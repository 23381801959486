import * as angular from 'angular'
import { alertCategoryDismiss } from './alert-category-dismiss.directive'
import './angular-bootstrap-tmpl-overrides'
import {DownloadManager} from './download-manager-service'
import {eventsService} from './events-service'
import {LazyLoadingDataService} from './lazy-loading-data.service'
import './local-storage'
import {MainController} from './main-controller'
import './misc-helpers' //  THIS IS A REALLY BAD NAME!!!
import {navigationStack} from './navigation-stack'
import './page.service'
import {recentlyViewedService} from './recently-viewed-service'
import {stateTransitions} from './state-transitions'
import {userDataService} from './user-data-service'
import {userAccountService} from './useraccount-service'

DownloadManager.$inject = ['utils', 'CONSTANTS', 'partyService', 'authService']

const app = angular.module('app', [
  'app.utils',
  'app.core',
  'app.alert',
  'app.correspondence',
  'app.search',
  'app.compensation',
  'app.cl-and-r',
  'app.batch-statements',
  'app.client',
  'app.client.me',
  'app.client.epay',
  'app.client.advisor',
  'app.client.directives',
  'app.policy',
  'app.auth',
  'app.helpers',
  'app.directives',
  'app.account',
  'app.terms.and.conditions',
  'ng-currency',
  'app.config',
  'app.credits',
  'app.replyable-requirements',
  'app.beneficiaries',
  'app.mfa-login',
  'app.nba-upload',
  'app.roles',
  'app.user-settings',
  'app.sales-achievement-reports',
  'app.transactions',
  //    '720kb.tooltips',
])

app.config(configure)
configure.$inject = ['$compileProvider']

  /* @ngInject */
function configure($compileProvider) {
    // $logProvider.debugEnabled(true)
  $compileProvider.preAssignBindingsEnabled(true)
}

angular
    .module('app')
    .service('downloadManager', DownloadManager)
    .service('lazyLoadingDataService', LazyLoadingDataService)
    .factory('eventsService', eventsService)
    .controller('MainController', MainController)
    .factory('navigationStack', navigationStack)
    .factory('recentlyViewedService', recentlyViewedService)
    .service('stateTransitions', stateTransitions)
    .factory('userDataService', userDataService)
    .factory('userAccountService', userAccountService)
    .directive('alertCategoryDismiss', alertCategoryDismiss)
