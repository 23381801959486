export class RoleChangeReponse {
  confirmation: string
  results: any[]
  status: number
  statusText: string

  constructor(confirmation, igwResponse) {
    this.status = igwResponse.status
    this.statusText = igwResponse.data?.result?.tc ? igwResponse.data.result.value : igwResponse.statusText || igwResponse.data.reasonPhrase
    this.results = igwResponse.data?.clientUpdateResponses

    if (this.status === 200) {
      this.confirmation = confirmation
    }
  }

  get hasError() {
    return this.status !== 200
  }
}
