/* eslint-disable no-empty-function */
/* eslint-disable no-underscore-dangle */
export class GoogleAnalyticsService {
  static $inject = ['$window', 'authService']

  constructor(private $window: any, private authService: any) { }

  /**
   *
   * @param event: string | object
   * @param action?
   * @param properties?
   * @param errorCode?
   */
  send(event: string, action: string , properties?: any, errorCode?: string): void {
    const data: object = this._buildGtmObject(event, action, properties, errorCode)

    if (this.$window.dataLayer) {
      this.$window.dataLayer.push(data)
    } else {
      console.warn('dataLayer is missing')
    }
  }

  /**
   *
   * @param event
   * @param action
   * @param properties
   * @param eCode
   */
  private _buildGtmObject(event: string, action: string, properties?: any, eCode?: any) {
    const hasParameters = typeof properties === 'object'
    const hasErrorCode = (hasParameters && typeof eCode !== 'undefined') || (!hasParameters && typeof properties !== 'undefined' && typeof eCode === 'undefined')
    const parameters = hasParameters ? properties : {}
    const errorCode = hasErrorCode && hasParameters ? eCode : hasErrorCode && !hasParameters ? properties : undefined
    const session = this.authService.getCurrentSession()
    const userId = session.loggedInAs
    const userType = this.authService.getUserType()
    const eventObject = Object.assign({
      event: event,
      action: action,
      user_id: userId,
      userType: userType,
      ...parameters,
    })

    if (hasErrorCode) eventObject.errorCode = errorCode

    // console.log('hasParameters: %s, hasErrorCode: %s', hasParameters, hasErrorCode, arguments)
    // console.log('****', event, action, parameters, errorCode, eventObject)

    return eventObject
  }
}
