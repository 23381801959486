export class ClientNavigationHeaderController implements ng.IController {

  static $inject = [
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'clientMeService',
    'CONSTANTS',
    'popupManager',
    'configService',
    'navigationService',
    '$q',
    'modalService',
  ]

  policies: any[] | null = null
  categories: any[]
  // inEpayState: boolean = false
  agentsCount: number = 0
  agent: string = ''
  showHeader: boolean = true
  myClientId: string
  myClientName: string
  clientListId: string
  clientListName: string
  myDashboardId: string
  myDashboardName: string
  myBusinessId: string
  myBusinessName: string
  myAgencyId: string
  myAgencyName: string
  hideBarHeaderClearWatch: any

  constructor(private $rootScope, private $state, private $window, private authService, private clientMeService,
    private CONSTANTS, private popupManager, private configService, public navigationService, private $q, private modalService) {

    this.popupManager.register(this.CONSTANTS.popups.MAIN_MENU_ID)
    this.showHeader = !this.$rootScope.hideBarHeader && this.authService.isAuthenticated()
    this.myClientId = CONSTANTS.modules.myclients.id
    this.myClientName = CONSTANTS.modules.myclients.name
    this.clientListId = CONSTANTS.modules.clientlist.id
    this.clientListName = CONSTANTS.modules.clientlist.name
    this.myDashboardId = CONSTANTS.modules.mydashboard.id
    this.myDashboardName = CONSTANTS.modules.mydashboard.name
    this.myBusinessId = CONSTANTS.modules.mybusiness.id
    this.myBusinessName = CONSTANTS.modules.mybusiness.name
    this.myAgencyId = CONSTANTS.modules.myagency.id
    this.myAgencyName = CONSTANTS.modules.myagency.name

    // this.showReplyable = this.configService.features.showReplyable && this.requirementsHelperService.notNeuteredUser()

  }

  /**
   * Returns a promise to make unit testing work!!!
   */
  $onInit() {
    this._applyLinkPermissions() // needed for initial value and unit tests

    // Watch for changes to the  `hideBarHeader` scope property.
    this.hideBarHeaderClearWatch = this.$rootScope.$watch('hideBarHeader', () => {
      this.showHeader = !this.$rootScope.hideBarHeader && this.authService.isAuthenticated()
    })

    return this.$q.resolve(this)
  }

  $onDestroy() {
    if (this.hideBarHeaderClearWatch) this.hideBarHeaderClearWatch()
  }

  private _getCurrentModuleID = () => this.$state.current?.data?.module ?? this.CONSTANTS.modules.myclients.id

  /**
   *
   */
  // tslint:disable-next-line:no-empty
  private _applyLinkPermissions() {
  }

  toggleAgentPopup(): void {
    return this.clientMeService.getMyAgents()
      .then(r => this._showAgentPopup(r))
  }

  // Technically this is a private method, but for unit test purposes it is public.
  _showAgentPopup(data?: any) {
    this.$rootScope.$broadcast('SHOW_AGENT_POPUP', data ? data.agents : data)
  }

  inEpayState(): boolean {
    return this.$state.current.name.includes('epay')
  }

  getCurrentModuleName(): string {
    return this.CONSTANTS.modules[this._getCurrentModuleID()]?.name
  }

  isMainMenuOpen(): boolean {
    return this.popupManager.isOpen(this.CONSTANTS.popups.MAIN_MENU_ID)
  }

  toggleMainMenu(e): void {
    this.popupManager.toggle(this.CONSTANTS.popups.MAIN_MENU_ID, e)
  }

  mobileHeaderTitle(): string {
    return this.$rootScope.mobileHeaderTitle
  }

  hideBarHeader(): boolean {
    return this.$rootScope.hideBarHeader
  }

  isInMfa() {
    return this.$state.current.name.includes('mfa')
  }

  isModuleActive(moduleID) {
    return this._getCurrentModuleID() === moduleID
  }

  showNormalMenu() {
    return this.isMyClientsModuleActive()
  }

  isMyClientsModuleActive(): boolean {
    return this._getCurrentModuleID() === this.CONSTANTS.modules.myclients.id
  }

  /**
   * ORIGINAL NOTE: this only considers the first 10 policies returned;
   *                waiting on a proper flag to check instead.
   *
   * TODO: Use the `numFound` property returned from whatismybusiness
   *       end-point.
   *
   * Q: Is `policy.holdingStatus` alway 'active' (1) for inforce
   * Q: When and where is this function called?
   */
  hasInforcePolicy(): boolean {
    if (this.policies) {
      return this.policies.filter((policy) => {
        return policy.holdingStatus === 'active'
      }).length !== 0
    }

    return false
  }

  showClientBackButton(): boolean {
    const userNotClient: boolean = this.authService.isAuthenticated() && !this.authService.isAClient()
    const isRouteGood: boolean = this.$state.current?.name !== 'client.me'
    const hasHistory: boolean = this.$window.history.length > 1

    return (userNotClient || isRouteGood) && hasHistory
  }

  showContactUsAndForms(): boolean {
    return !this.isInMfa() && !this.inEpayState()  // || self.hasInforcePolicy()
  }

  // MOVED TO `navigationService`
  /* istanbul ignore next */
  // self._setWindowLocationHref = function(url) { // For mocking in tests
  //   window.location.href = url
  // }

  showAddressLink = (): boolean => Boolean(this.configService.features.clientManageAddresses)

  // This was an async function that did not return a promise. Now it does,
  // TODO: Fix place where this is called from.
  //
  // NOTE: For some reason the original code is returning an empty object
  //       when there are no agents. This does not make sense because all
  //       of the unit tests expect a string with the agents name, or an
  //       empty string.
  //
  populateAgentsCount() {
    return this.clientMeService.getMyAgents().then((data: any) => {
      this.agentsCount = data.agents.length
      this.agent = data.agents[0]?.agent ?? ''
      return data.agents
    })
  }

  showForms(): any {
    this.$rootScope.$broadcast(this.CONSTANTS.events.CLOSE_POPUPS_AND_MENUS)
    this.modalService.openComponent('clientForms', {
      // windowClass: 'warning-modal',
      size: 'lg',
      keyboard: true,
      backdrop: 'static',
    }, { dataStyle: 'getOrganizedForms' })

    return this.modalService.modalInstance.result
  }
}
