/* eslint-disable no-empty-function */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { IController, IQService } from 'angular'

import { BeneficiariesDataProviderService } from '../../beneficiaries/beneficiary-data-provider'
import { Beneficiaries } from '../../beneficiaries/classes/beneficiaries'
import { RoleRelationshipsService } from '../../beneficiaries/relationships/role-relationships-service'
import { IAddressCountry } from '../../address/types'
import { BENEFICIARY_VIEWS, CLIENT_BENEFICIARY_VIEW_STATES } from '../../beneficiaries/constants/BENEFICIARY_VIEWS'
import { RolesPermissionService } from '../roles-permission-service'
import { ClientBeneficiariesController } from '../client-bene.controller'
import { RoleChangeReponse } from '../../beneficiaries/classes/role-change-response'
import { BENEFICIARY_EVENTS, BENEFICIARY_GA_EVENTS } from '../../beneficiaries/beneficiary-constants'
import { GoogleAnalyticsService } from '../../../components/google-analytics'
import { IRoleEligibilityResult } from '../../roles/types'
import { LoggingService } from '../../../utils/logging'

// import { IAddressCountry } from '../address/types'
// import { Beneficiaries } from '../beneficiaries/classes/beneficiaries'
// import { RoleChangeReponse } from '../beneficiaries/classes/role-change-response'
// import { BENEFICIARY_VIEWS } from '../beneficiaries/constants/BENEFICIARY_VIEWS'
// import { IEditableBeneficiary } from '../beneficiaries/types'
const MODULE = 'client-beneficiary-change'
class ClientBeneListController implements IController {
  static $inject: any = ['$scope', '$q', '$state', 'configService', 'authService', 'beneficiariesDataProvider', 'roleRelationshipsService', 'modalService', 'rolesPermissionService', '$element', '$window', 'googleAnalyticsService', 'loggingService']

  // public properties
  clientBeneCtrl: ClientBeneficiariesController
  eligibility: IRoleEligibilityResult
  policySummary: any
  pid: any

  // showAllSections: boolean = false
  acordCountries: IAddressCountry

  bypassCheck: boolean = false
  relationships: any = {}
  session: any
  datePickerOptions: any
  effectiveDate: any
  showCorrespondenceToggle: boolean
  sliderLabelText: string = 'Edit Mode'
  editSwitch: boolean = true
  sections: Beneficiaries[] = []
  disableSaveButton: boolean = true
  debugData: any
  changeResults: RoleChangeReponse
  beneficiaryMetadata: any

  private _deregisterFunctions: Function[] = []
  private _readyCounter: any = {}

  // private _categoryForms: any = {}
  // private _hasChanges: any = {}
  // private _invalidCategories: any = {}
  // private payload: any = null

  // groupedBeneficiaries: IEditableBeneficiary
  // partyList: any[]
  // owners: any[]
  // showCategories: boolean = false
  // showSuffixes: boolean = false
  // confirmation: any
  // changeResults: RoleChangeReponse | null = null
  // canSuppressCorrespondence: boolean
  // sliderLabelText: string
  // sendCorrespondence: boolean
  // isDeathClaim: boolean
  // private _ownerRoleIds: string[]
  // private _editorOpen: boolean = false

  constructor(private $scope: any, private $q: IQService, private $state, private configService, private authService: any, private beneficiariesDataProvider: BeneficiariesDataProviderService, private roleRelationshipsService: RoleRelationshipsService, private modalService, private rolesPermissionService: RolesPermissionService, private $element, private $window, private googleAnalyticsService: GoogleAnalyticsService, private loggingService: LoggingService) {
    this.clientBeneCtrl = this.$scope.$parent.clientBeneMainCtrl
    this.policySummary = this.clientBeneCtrl.policySummary
    this.pid = this.policySummary.polNumber
    this.session = authService.getCurrentSession()
    this.effectiveDate = new Date()
    this.showCorrespondenceToggle = false
  }


  get isBeneficiaryManagmentAllowed(): boolean {
    return true
  }

  get isReadOnly(): boolean {
    const { eligible } = this.rolesPermissionService.clientCanManageBeneficiaries(this.policySummary, this.clientBeneCtrl.organizedParties, this.beneficiaryMetadata)

    // console.log(eligible, reasons)

    return !eligible
  }

  get showAllSections(): boolean {
    return this.clientBeneCtrl.showAllSections && this.viewState.showCategories
  }

  get viewState(): any {
    try {
      const { eligible } = this.rolesPermissionService.clientCanManageBeneficiaries(this.policySummary, this.clientBeneCtrl.organizedParties, this.beneficiaryMetadata)

      // console.log(this.changeResults, eligible, reasons)

      if (this.changeResults) {
        if (this.changeResults.hasError) {
          // this.loggingService.error('user entered viewState: ' + BENEFICIARY_VIEWS.ERROR, MODULE)
          return CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.ERROR]
        } else {
          // this.loggingService.info('user entered viewState: ' + BENEFICIARY_VIEWS.CONFIRMATION, MODULE)
          return CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.CONFIRMATION]
        }
      }

      if ((eligible && this.configService.features.enableSelfServeBeneficiaries) || (!this.configService.features.enableSelfServeBeneficiaries && this.beneficiaryMetadata.featureFlagUserWhitelist.includes(this.session.loggedInAs))) {
        // this.loggingService.info('user entered viewState: ' + BENEFICIARY_VIEWS.EDIT, MODULE)
        return CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.EDIT]
      } else {
        return CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.READONLY]
      }

    } catch (err) {
      // this.loggingService.error('user entered viewState: ' + BENEFICIARY_VIEWS.LOADING, MODULE)
      return CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.LOADING]
    }
  }

  /**
 * Called when the call to `BeneficiariesDataProvider.submitBeneficiaries` results
 * in a CoreServicesGateway error.
 *
 * Sets `BeneficiariesController.viewState` to `BENEFICIARY_VIEWS.ERROR`.
 *
 * @param err
 */
  _errorHandler(err: any): void {
    if (!(err instanceof RoleChangeReponse)) {
      this.changeResults = new RoleChangeReponse(null, { statusText: err.message, status: -1 })
    } else {
      this.changeResults = err
    }

    const parameters = { reason: this.changeResults.statusText, policyId: this.pid }
    const gaData: any = Object.assign({}, BENEFICIARY_GA_EVENTS.CP_FAIL, { parameters, errorCode: this.changeResults.status })

    // console.error('_errorHandler', err, gaData)
    this.loggingService.error(`Fail (viewState: ${this.viewState.state}): ${JSON.stringify(this.changeResults)}`, MODULE)

    this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)

    // this.viewState = CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.ERROR]
  }

  /**
   * Called when the call to `BeneficiariesDataProvider.submitBeneficiaries` results
   * in a successful submission.
   *
   * Calls BeneficiariesController._getAllSections() to refresh the list of
   * current beneficiaries.
   *
   * When completed, set the `BeneficiariesController.viewState` to `BENEFICIARY_VIEWS.CONFIRMATION`
   *
   * @param result
   */
  _successHandler(result: RoleChangeReponse): void {
    console.log('_successHandler:result', result)
    // result.confirmation = ''

    this.$scope.$applyAsync(() => {
      // When the confirmation number is missing make the resonse look like a 500 error. This will trigger the error viewState.
      if(!result.confirmation && !result.hasError) {
        result.status = 500
        result.statusText = 'Confirmation number does not exists.'
      }

      if (result.hasError) {
        // @NOTE: for some reason 500 errors coming back in the success handler. Transfering over to error handler so GA logs the correct event.
        this._errorHandler({ errorCode: result.status, errorMessage: result.statusText, hasError: true})
      } else {
        const gaData = Object.assign({}, BENEFICIARY_GA_EVENTS.CP_SUCCESS, { parameters: { reason: 'Success', policyId: this.pid } })

        this.changeResults = result

        this.loggingService.info(`Success (viewState: ${this.viewState.state}): ${JSON.stringify(result)}`, MODULE)
        this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters)
      }
    })
  }

  submit(): void {
    this.loggingService.info('User clicked submit button for beneficiary changes', 'client-beneficiary-change')

    this.clientBeneCtrl.submitChanges()
      .then((result) => this._successHandler(result))
      .catch((err) => this._errorHandler(err))
  }
  /**
   * This function is used as an output binding to `onReady` on the beneficiary-category components.
   * This function expects a categoryIndex value to identify which category has called back, and
   * the name of the `BENEFICIARY_VIEWS` that are being waited on.
   *
   * If the current `BeneficiariesComponent.viewState` is `BENEFICIARY_VIEWS.CONFIRMATION` this does
   * nothing and returns immediately. For any other view state, the function waits until the
   * `BeneficiariesComponent._readyCounter` for the active view state reaches three (3). When it
   * does the current `BeneficiariesComponent.viewState` is set to the active one.
   *
   * @param categoryIndex
   * @param view
   * @returns undefined
   */
  categoryReady(categoryIndex: number): void {
    const view: any = this.viewState?.state

    if ([BENEFICIARY_VIEWS.LOADING, BENEFICIARY_VIEWS.READONLY].includes(view)) return

    if (!this._readyCounter[view]) this._readyCounter[view] = []

    this._readyCounter[view].push(categoryIndex)

    if (this._readyCounter[view].length === 3) {
      // this.viewState = CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.EDIT]
      this._readyCounter[view] = null
      // console.log('viewState: current: %s => new: %s vuew', this.viewState?.state, view)
    }
  }

  cancelChanges(): void {
    if (this.clientBeneCtrl.canSubmitData) {
      this.clientBeneCtrl.openWarningDialog().then(() => {
        this.clientBeneCtrl.setCurrentBeneficiary()
        this.$state.go('myPolicy', { pid: this.pid })
      })
        .catch(() => {
          this.$element[0].scrollIntoView()
        })
    } else {
      this.$state.go('myPolicy', { pid: this.pid })
    }
  }

  changesComplete() {
    this.loggingService.info('User completed bene changes for policy: ' + this.pid, MODULE)
    this.$state.go('myPolicy', { pid: this.pid }, { reload: true })
  }

  /**
   * AngularJS life-cycle hook.
   */
  $onInit(): void {
    if (this.policySummary.clients.owners.length === 0) {
      console.warn('This policy is missing CCS Owner data. Can\'t continiue.')

      return
    }

    // this._deregisterFunctions.push(this.$scope.$on('$stateChangeStart', (event, toState, toParams): void => {
    //   const hasChanges = false // Needs logic to find if actual changes exist

    //   if (this.bypassCheck) return

    //   if (hasChanges) {
    //     // eslint-disable-next-line @typescript-eslint/no-floating-promises
    //     this.clientBeneCtrl.openWarningDialog().then(() => {
    //       this.bypassCheck = true
    //       this.$state.go(toState.name, { id: toParams.id })
    //       event.preventDefault()
    //     })
    //     event.preventDefault()
    //   }
    // }))

    this.$scope.$on('$locationChangeSuccess', () => {
      this.$window.scrollTo(0, 0)
    })

  }

  // $doCheck(): void {
  //   // this.viewState = this.editSwitch ? CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.EDIT] : CLIENT_BENEFICIARY_VIEW_STATES[BENEFICIARY_VIEWS.READONLY]

  //   console.log(this.$scope)
  // }

  $onDestroy(): void {
    this._deregisterFunctions.forEach(fn => fn())
  }
}

export const clientBeneListComponentConfig = {
  templateUrl: 'app/client/client-bene-edit/list-route/main-view.html',
  controller: ClientBeneListController,
  controllerAs: 'clientBeneListCtrl',
  bindings: {
    beneficiaryMetadata: '<',
  },
}
