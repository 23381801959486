/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import * as angular from 'angular'
import { NavigationService } from './navigation-service'
import { StateService } from '@uirouter/core'
import ng from 'angular'

export const ROLE_HOMEOFFICE = 'HOMEOFFICE'
export const ROLE_ASSISTANT = 'ASSISTANT'
export const ROLE_AGENT = 'AGENT'
export const ROLE_FIELD_MGMT = 'FIELD MGMT'
export const ROLE_DSTSUPPORT = 'DSTSUPPORT'
export const ROLE_HTK_ASSISTANT = 'HTK-ASSISTANT'

export const PERMISSION_IS_ADVISOR = 'isAdvisor'

declare interface GlobalNavLink {
  name: string
  url: string
  newWindow: boolean
}
export class WgNavigationHeaderController implements ng.IController {
  static $inject = [
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'clientMeService',
    'compensationService',
    'CONSTANTS',
    'popupManager',
    'configService',
    'navigationService',
    '$q',
    'requirementsHelperService',
    'modalService',
  ]

  policies: any[] | null = null
  categories: any[]
  numberOfAlerts: number
  showAlertsIndicator: boolean = false
  showClientList: boolean = false
  compensationAvailable: boolean = false
  dismissAlertsActive: boolean = false
  isMyBusinessModuleAccesible: boolean = false
  showBatchStatements: boolean = false
  showCLandR: boolean = false
  showInlineClubs: boolean = false
  agent: string = ''
  showHeader: boolean = true
  myClientId: string
  myClientName: string
  clientListId: string
  clientListName: string
  myDashboardId: string
  myDashboardName: string
  myBusinessId: string
  myBusinessName: string
  batchStementsId: string
  batchStatementsName: string
  myAgencyId: string
  myAgencyName: string
  showExternalClubCredits: boolean = false
  clubCreditsURL: string
  hideBarHeaderClearWatch: any
  showReplyable: boolean
  showNotifications: boolean
  replyableId: string
  replyableName: string
  eAppUrl: string
  isHomeOffice: boolean
  showGlobalNav: boolean
  notifications: any
  showNbaOption: boolean = false

  constructor(
    private $rootScope,
    private $state: StateService,
    private $window,
    private authService,
    private clientMeService,
    private compensationService,
    private CONSTANTS,
    private popupManager,
    private configService,
    public navigationService: NavigationService,
    private $q: angular.IQService,
    private requirementsHelperService,
    private modalService,
  ) {
    this.popupManager.register(this.CONSTANTS.popups.MAIN_MENU_ID)
    this.popupManager.register(this.CONSTANTS.popups.ALERTS_MENU_ID)
    this.showCLandR =
      this.configService.features &&
      Boolean(this.configService?.features.showCLandR)

    this.showHeader =
      !this.$rootScope.hideBarHeader && this.authService.isAuthenticated()
    this.myClientId = CONSTANTS.modules.myclients.id
    this.myClientName = CONSTANTS.modules.myclients.name
    this.clientListId = CONSTANTS.modules.clientlist.id
    this.clientListName = CONSTANTS.modules.clientlist.name
    this.myDashboardId = CONSTANTS.modules.mydashboard.id
    this.myDashboardName = CONSTANTS.modules.mydashboard.name
    this.myBusinessId = CONSTANTS.modules.mybusiness.id
    this.myBusinessName = CONSTANTS.modules.mybusiness.name
    this.replyableId = CONSTANTS.modules.ReplyableRequirements.id
    this.replyableName = CONSTANTS.modules.ReplyableRequirements.name
    this.batchStementsId = CONSTANTS.modules.batchstatements.id
    this.batchStatementsName = CONSTANTS.modules.batchstatements.name
    this.myAgencyId = CONSTANTS.modules.myagency.id
    this.myAgencyName = CONSTANTS.modules.myagency.name
    this.clubCreditsURL = this.configService.clubCreditsURL
    this.notifications = CONSTANTS.modules.notifications
    this.toggleAlertsMenu = this.toggleAlertsMenu.bind(this) // Need because this function is pass to child components
    this.eAppUrl = this.configService.eAppUrl
    this.isHomeOffice = this.authService.isInRole(ROLE_HOMEOFFICE)
    this.showGlobalNav = this.configService.features.enableGlobalNav
    this.showNbaOption = this.configService.features.newBusinessApplicationUploadEnabled && ((authService.isHomeOfficeUser() && authService.getCanAccessResponse().canSubmitApplications) || !authService.isHomeOfficeUser())
    // this.showReplyable = this.configService.features.showReplyable && this.requirementsHelperService.notNeuteredUser()

  }

  /**
   * Returns a promise to make unit testing work!!!
   */
  $onInit() {
    this._applyLinkPermissions() // needed for initial value and unit tests

    if (this.showGlobalNav) {
      this.setupGlobalNav()
    }

    this.$rootScope.$on('activeAgentChanged', () => {
      this._updateShowReplyable().then(() => this._applyLinkPermissions())
    })

    // Watch for changes to the  `hideBarHeader` scope property.
    this.hideBarHeaderClearWatch = this.$rootScope.$watch(
      'hideBarHeader',
      () => {
        this.showHeader =
          !this.$rootScope.hideBarHeader && this.authService.isAuthenticated()
      },
    )

    // Release any resource consumed by $watch
    this.$rootScope.$on('$destroy', () => {
      if (this.hideBarHeaderClearWatch) this.hideBarHeaderClearWatch()
    })

    if (this.authService.isAuthenticated()) {
      this.compensationAvailable = this.authService.checkRoles([ROLE_ASSISTANT, ROLE_DSTSUPPORT, ROLE_FIELD_MGMT, ROLE_HTK_ASSISTANT]) || this.authService.matchAnyPermission([PERMISSION_IS_ADVISOR])
      if (!this.authService.isClientView()) {
        return this.$q
          .all([
            // Load alerts to display unread count in alerts bubble
            // this._loadAlerts(),

            // Need to make this call to determine if the 'Compensation' link is visable.
            // this.checkIfCompensationAvailable(),  NOTE: Commented this out because it's no longer required.
            this._updateShowReplyable(),
          ])
          .then(() => this)
          .catch((err) => console.log(err))
      }
      return this.$q.resolve(this)
    }
    return this.$q.resolve(this)
  }

  private _finishGlobalNavConfig(globalNavData) {
    // console.info('site links are', siteLinks)
    const globalNavAdditonLInks: GlobalNavLink[] = [
      {
        name: 'Penn Mutual Directory',
        url: 'https://home2.pennmutual.com/directory/',
        newWindow: true,
      },
      {
        name: 'Webmail',
        url: 'https://owa.pennmutual.com/',
        newWindow: true,
      },
    ]

    const sid = this.authService.getSessionID()
    let logoutURL = sid ? this.CONSTANTS.logoutURL + `?sid=${sid}` : this.CONSTANTS.logoutURL

    logoutURL = logoutURL.replace('{context}', 'agent') + '&reason=user invoked logout'


    const globalNavParent = document.getElementById('insightGlobalNavTag')
    const globalNav = document.createElement('pml-global-nav')

    globalNav.setAttribute('sites', JSON.stringify(globalNavData?.siteNavMenu ?? []))
    globalNav.setAttribute('activeSite', 'insight')
    globalNav.setAttribute('userMenuLinks', JSON.stringify(globalNavData?.userNavMenu ?? []),)
    globalNav.setAttribute('loginUrl', `${this.CONSTANTS.authProxyURL}/agent`)
    globalNav.setAttribute('logoutUrl', logoutURL)
    globalNav.setAttribute('myProfileUrl', globalNavData?.myProfileUrl)
    globalNav.setAttribute('usernamePrefix', globalNavData?.usernamePrefix)
    globalNav.setAttribute('usernameSuffix', globalNavData?.usernameSuffix)

    if (this.authService.isAuthenticated()) {
      const user = {
        firstName: this.authService.getLogin(),
      }

      globalNav.setAttribute('user', JSON.stringify(user))
    }

    globalNavParent?.appendChild(globalNav)

  }

  private setupGlobalNav() {
    this._finishGlobalNavConfig(this.configService.globalNavSites)

    // this.navigationService.getSiteLinks()
    //   .then((siteLinks) => this._finishGlobalNavConfig(siteLinks))
    //   .catch(() => this._finishGlobalNavConfig(null))
  }

  private _getCurrentModuleID = () =>
    this.$state.current?.data?.module ?? this.CONSTANTS.modules.myclients.id

  // Technically this is a private method, but for unit test purposes it is public.
  _showAgentPopup(data?: any) {
    this.$rootScope.$broadcast('SHOW_AGENT_POPUP', data ? data.agents : data)
  }

  /**
   *
   */
  private _applyLinkPermissions() {
    this.showAlertsIndicator = this.showTheAlertsIndicator()
    this.showInlineClubs = this.showInlineClubsLink()
    this.showClientList =
      Boolean(this.configService.features.showClientList) &&
      this.$rootScope.isAgentView
    this.showBatchStatements = this._resolveShowBatchStatements()
    this.showNotifications = this._resolveNotifications()
  }


  /**
   *
   */
  private _updateShowReplyable() {
    return this.requirementsHelperService
      .canCurrentUserDoReplies()
      .then((canViewReplyValue) => {
        this.showReplyable =
          this.configService.features.showReplyable && canViewReplyValue
      })
  }

  /**
   *
   */
  private _resolveShowBatchStatements() {
    const features = this.configService.features || {}
    const isHOAndAllowed = this.authService.isInRole(ROLE_HOMEOFFICE) // && this.authService.partyService.getAgentKey() ? true : false
    const isAssistant = this.authService.isInRole(ROLE_ASSISTANT)
    const isAgent = this.authService.isInRole(ROLE_AGENT)
    const isValidRole =
      (isHOAndAllowed && features.batchStatementsAllowHO) ||
      isAssistant ||
      isAgent
    const showBS = features.batchStatements && isValidRole

    return showBS
  }

  private _resolveNotifications(): boolean {
    const session = this.authService.getCurrentSession()
    const features = this.configService.features || {}
    const isInProperRole = this.authService.checkRoles([ROLE_AGENT, ROLE_ASSISTANT, ROLE_FIELD_MGMT]) || this.authService.matchAnyPermission([PERMISSION_IS_ADVISOR])
    const homeOfficeCanView = this.authService.checkRoles([ROLE_HOMEOFFICE]) && (session.canAccess.canViewUserSettings || session.canAccess.canManageUserSettings)

    return features.showNotifications && (isInProperRole || homeOfficeCanView)
  }

  // private _resolveExternalClubCredits() {
  //   const isHO = this.authService.isInRole(ROLE_HOMEOFFICE)
  //   const isValidRole = !isHO
  //
  //   return isValidRole && this.configService.features.externalClubCreditsLink
  // }

  public showTheAlertsIndicator(): boolean {
    return (
      (!this.isInRole('HOMEOFFICE') || this.authService.isAgentFilterSet()) &&
      this.configService?.features.showAlertsIndicator
    )
  }

  public showInlineClubsLink(): boolean {
    return true // return !this.isInRole('HOMEOFFICE') || this.authService.isAgentFilterSet()
  }

  getCurrentModuleName(): string {
    return this.CONSTANTS.modules[this._getCurrentModuleID()]?.name
  }

  isMainMenuOpen(): boolean {
    return this.popupManager.isOpen(this.CONSTANTS.popups.MAIN_MENU_ID)
  }

  toggleMainMenu(e): void {
    this.popupManager.toggle(this.CONSTANTS.popups.MAIN_MENU_ID, e) 
    e.stopPropagation()
  }

  toggleAlertsMenu(e): void {
    if (this.$rootScope.isAgentView) {
      this.popupManager.toggle(this.CONSTANTS.popups.ALERTS_MENU_ID, e)
    }
  }

  isAlertsMenuOpen(): boolean {
    return this.popupManager.isOpen(this.CONSTANTS.popups.ALERTS_MENU_ID)
  }

  mobileHeaderTitle(): string {
    return this.$rootScope.mobileHeaderTitle
  }

  hideBarHeader(): boolean {
    return this.$rootScope.hideBarHeader
  }

  isModuleActive(moduleID) {
    return this._getCurrentModuleID() === moduleID
  }

  showNormalMenu() {
    return this.isMyClientsModuleActive() || this.isMyDashboardModuleActive() || this.isTransactionsModuleActive()
  }

  isMyDashboardModuleActive() {
    // TODO: Spell this function name correctly isMyDashboardModuleActive -> isMyDashboardModuleActive.
    return this._getCurrentModuleID() === this.CONSTANTS.modules.mydashboard.id
  }

  isMyClientsModuleActive(): boolean {
    return this._getCurrentModuleID() === this.CONSTANTS.modules.myclients.id
  }

  isTransactionsModuleActive(): boolean {
    return this._getCurrentModuleID() === this.CONSTANTS.modules.transactions.id
  }

  isMyBusinessModuleActive(): boolean {
    return this._getCurrentModuleID() === this.CONSTANTS.modules.mybusiness.id
  }

  toggleAgentPopup(): void {
    return this.clientMeService
      .getMyAgents()
      .then((r) => this._showAgentPopup(r))
  }

  /**
   * Returns void, but sets this.compensationAvailable
   *
   * NOTE: JAG: Fixing this for the sake of unit testing
   */
  checkIfCompensationAvailable() {
    if (this.authService.isAuthenticated() && !this.authService.isAClient()) {
      return this.compensationService
        .checkIfCompensationLast3Years()
        .then((httpData) => {
          const docs = httpData.documents

          this.compensationAvailable =
            Array.isArray(docs) && docs && docs.length > 0
        })
        .catch(() => {
          console.error('Error initializing compensation module')
        })
    }
    this.compensationAvailable = false
    return this.$q.resolve()
  }

  /**
   * ORIGINAL NOTE: this only considers the first 10 policies returned;
   *                waiting on a proper flag to check instead.
   *
   * TODO: Use the `numFound` property returned from whatismybusiness
   *       end-point.
   *
   * Q: Is `policy.holdingStatus` alway 'active' (1) for inforce
   * Q: When and where is this function called?
   */
  hasInforcePolicy(): boolean {
    if (this.policies) {
      return (
        this.policies.filter((policy) => {
          return policy.holdingStatus === 'active'
        }).length !== 0
      )
    }

    return false
  }

  showClientBackButton(): boolean {
    const userNotClient: boolean =
      this.authService.isAuthenticated() && !this.authService.isAClient()
    const isRouteGood: boolean = this.$state.current?.name !== 'client.me'
    const hasHistory: boolean = this.$window.history.length > 1

    return (userNotClient || isRouteGood) && hasHistory
  }

  showForms(): any {
    this.$rootScope.$broadcast(this.CONSTANTS.events.CLOSE_POPUPS_AND_MENUS)
    this.modalService.openComponent('clientForms', {
      // windowClass: 'warning-modal',
      size: 'xl',
      keyboard: true,
      backdrop: true,
    }, { dataStyle: 'getAllForms' })

  return this.modalService.modalInstance.result
}

  // MOVED TO `navigationService`
  /* istanbul ignore next */
  // self._setWindowLocationHref = function(url) { // For mocking in tests
  //   window.location.href = url
  // }

  isInRole = (role): boolean => this.authService.isInRole(role)

  showAddressLink = (): boolean =>
    Boolean(this.configService.features.clientManageAddresses)

  // This was an async function that did not return a promise. Now it does,
  // TODO: Fix place where this is called from.
  //

}
