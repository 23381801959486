/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAttributes, INgModelController, IScope } from 'angular'

class SliderSwitchController {
  static $inject = ['$scope', '$attrs']

  toggle: boolean = false
  onClick: (e?: Event) => void
  identifier: string
  ngModel: INgModelController
  disabled: boolean = false

  private unregister: any[] = []

  constructor(private $scope: IScope, private $attrs: IAttributes) {}

  $onInit(): void {
    this.unregister.push(this.$scope.$watch('ctrl.ngModel.$viewValue', (n: boolean) => {
        this.toggle = n
    }))
    this.unregister.push(this.$scope.$watch('ctrl.toggle', () => {
      this.ngModel.$setViewValue(this.toggle)
    }))
  }

  $onDestroy(): void {
    this.unregister.forEach((fn: () => void) => fn())
  }
}

const SliderSwitch = {
  templateUrl: 'app/components/slider-switch/slider-switch.html',
  controller: SliderSwitchController,
  controllerAs: 'ctrl',
  require: {ngModel: 'ngModel'},
  bindings: {
    labelText: '=',
    onClick: '&',
    disabled: '=',
  },
}

export {SliderSwitch, SliderSwitchController}
