import { FilterFactory, IHttpService, IPromise, IQService } from "angular"

export class CrafterService {
  static $inject = ['$http', '$q']

  metadata: any = {}
  getBeneficiaryMetadata: Function = () => this.getMetadata('beneficiaries', true)
  getContactMetadata: Function = () => this.getMetadata('contact-us', false).then((result) => {
    return result.data.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()

      if (nameA === 'Financial Professional'.toLowerCase()) {
        return -1
      }

      if (nameB === 'Financial Professional'.toLowerCase()) {
        return 1
      }

      return nameA.localeCompare(nameB)
    }).map((item: any, index: number) => {
      return {...item, index, selected: false}
    })
  })

  constructor(private $http: IHttpService, private $q: IQService) { }

  getMetadata(type: string, useListItemHandler: boolean): any {
    const url = `/crafter/metadata/${type}`

    if (!this.metadata[type]) {
      return this.$http.get(url)
        .then((response: any) => {
          if (useListItemHandler) {
            this.metadata[type] = response.data.reduce((acc, item) => {
              acc[item.identifier] = item.listData.split('\n')

              return acc
            }, {})

            return this.metadata[type]
          }

          console.log(this.metadata[type])
          return response
        })
        .catch((err) => {
          console.error(err)

          return []
        })
    }

    return this.$q.resolve(this.metadata[type])
  }
}