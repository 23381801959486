export {CompensationController}

CompensationController.$inject = ['$scope', 'compensationResult', 'compensationService', 'authService', 'utils', 'CONSTANTS', 'deviceUtils', 'popupManager']

  /* @ngInject */
function CompensationController($scope, compensationResult, compensationService, authService, utils, CONSTANTS, deviceUtils, popup) {
  const self = this
  let latestDocuments = {}
  const docTypePopupId = 'COMPENSATION_DOC_TYPES' // TODO should this be named something unique from correspondence docTypes?

  self.filters = []
  self.DEFAULT_FILTER_VALUE = 'None'
  self.appliedFiltersString = self.DEFAULT_FILTER_VALUE
  self.years = []
  self.selectedYear = null
  self.errorMessage = ''
  self.isThereError = false
  self.SCROLL_DISTANCE = 1

  popup.register(docTypePopupId)

  self.getSelectedDocTypes = function() {
    const checkedFilters = self._getCheckedFilters()
    const filterTypeCodesToQuery = self._getFilterTypeCodesToQuery(checkedFilters)
    const checkedFilterDescriptions = checkedFilters
        .map(function(filter) {
          return filter.description
        })

    popup.close(docTypePopupId)
    self.appliedFiltersString = checkedFilterDescriptions.length ? checkedFilterDescriptions.join(', ') : self.DEFAULT_FILTER_VALUE
    compensationService
        .getMostRecentCompensations({filters: filterTypeCodesToQuery})
        .then(self._renderCompensations)
  }

  self.isDocTypeMenuOpen = function() {
    return popup.isOpen(docTypePopupId)
  }

  self.isScrollDisabled = function() {
    return self.selectedYear.selectedMonth.loadingData || !self.selectedYear.selectedMonth.moreData
  }

  self.isFilterActive = function() {
    return self.appliedFiltersString !== self.DEFAULT_FILTER_VALUE
  }

  self.isLoading = function() {
    return self.selectedYear.selectedMonth.loadingData
  }

  self.nextPage = function() {
    if ((self.selectedYear.selectedMonth.currentPage > 0) &&
        self.selectedYear.selectedMonth.moreData && !self.selectedYear.selectedMonth.loadingData) {
      loadSelectedMonth()
    }
  }

  function setError(error) {
    self.errorMessage = error
    self.isThereError = Boolean(self.errorMessage)
  }

  function initMonths(year, month) {
    const months = []
    let i

    for (i = 0; i < CONSTANTS.MONTHS.length; i++) {
      months.push({
        name: CONSTANTS.MONTHS[i],
        value: utils.minTwoDigits(i + 1),
        documents: [],
        current: (i === month),
        currentPage: 0,
        moreData: true,
        loadingData: false,
      })
    }
    return months
  }

  self.getClassByType = function(type) {
    let lowercaseType

    if (type) {
      lowercaseType = type.toLowerCase()
      if (CONSTANTS.correspondenceType[lowercaseType]) {
        return CONSTANTS.correspondenceType[lowercaseType]
      }
    }

    return 'icon-bill'
  }

  self.toggleDocTypeMenu = popup.toggle.bind(popup, docTypePopupId)

  self.toggleMenu = function(year, event) {
    event.stopPropagation()
    year.isMonthSelectOpen = !year.isMonthSelectOpen
  }

  self.setSelectedMonth = function(month, year) {
    year.isMonthSelectOpen = false

    self.selectedYear = year
    self.selectedYear.selectedMonth = month || year.selectedMonth

    self.selectedYear.selectedMonth.documents = []
    self.selectedYear.selectedMonth.moreData = true
    self.selectedYear.selectedMonth.currentPage = 0
    latestDocuments = {}
    loadSelectedMonth()
  }

  $scope.$on(CONSTANTS.events.CLOSE_POPUPS_AND_MENUS, function() {
    let i

    for (i = 0; i < self.years.length; i++) {
      self.years[i].isMonthSelectOpen = false
    }
  })

  self.showDocumentDetail = function(docId) {
    let url

    if (docId) {
      url = CONSTANTS.compensationDownloadURL.replace('{0}', docId).replace('{1}', authService.getSessionID())

      if (deviceUtils.isMobilePlatform()) {
        window.open(url, '_system')
      } else {
        window.open(url, '_blank')
      }
    }
  }

  self.openADP = function() {
    const target = window.device ? '_system' : '_blank'

    if (dataLayer) {
      dataLayer.push({
        'event': 'Link to Wells',
      })
    }

    window.open('https://ipay.adp.com/', target)
  }

  self._getCheckedFilters = function() {
    return self.filters
        .filter(function(filter) {
          return filter.checked
        })
  }

  self._getFilterTypeCodesToQuery = function(checkedFilters) {
    return checkedFilters
        .map(function(filter) {
          return filter.typeCode
        })
  }

  self._initYears = function(resultFromApi) {
    const lastYear = resultFromApi.year.name
    const firstYear = lastYear - 2
    let currentYear
    let newYear

    self.years = []

    for (currentYear = lastYear; currentYear >= firstYear; currentYear--) {
      newYear = {
        name: currentYear,
        months: initMonths(currentYear, resultFromApi.month.value),
      }

      if (currentYear === resultFromApi.year.name) {
        newYear.selectedMonth = newYear.months[resultFromApi.month.value]
      } else {
        newYear.selectedMonth = newYear.months[0]
      }

      self.years.push(newYear)
    }

    self.selectedYear = self.years[0]
  }

  self._setAllFilters = function() {
    CONSTANTS.compensationDocTypes.forEach(function(docTypeObject) {
      self.filters.push({checked: false, description: docTypeObject.description, typeCode: docTypeObject.typeCode})
    })
  }

  self._setFilters = function(httpData) {
    if (!httpData.error && httpData.data.documents.length) {
      httpData.data.documents.forEach(function(doc) {
        self.filters.push({checked: false, description: doc.docType, typeCode: doc.docTypeTC})
      })
    } else {
      self._setAllFilters()
    }
  }

  function isDocumentInSelectedMonth(doc) {
    return self.selectedYear.name === doc.modDatetime.getFullYear() &&
        Number(self.selectedYear.selectedMonth.value) === doc.modDatetime.getMonth() + 1
  }

  function loadCompensations(docs) {
    let increasePageNumber = false
    let i
    let doc
    let docKey
    let latestDoc

    if (!docs || docs.length === 0) {
      self.selectedYear.selectedMonth.moreData = false
      return
    }

      // TODO: This loop is duplicated in correspondence-controllers.js
    for (i = 0; i < docs.length; i++) {
      doc = docs[i]

      doc.modDatetime = new Date(doc.modDate)
      doc.day = doc.modDatetime.getDate()
      doc.monthName = CONSTANTS.MONTHS[doc.modDatetime.getMonth()]
      docKey = doc.docType + doc.recipient
      latestDoc = latestDocuments[docKey]

      if (latestDoc) {
        if (latestDoc.day < doc.day) {
          latestDoc.latest = false
          doc.latest = true
          latestDocuments[docKey] = doc
        } else if (latestDoc.day === doc.day) {
          doc.latest = true
        }
      } else {
        latestDocuments[docKey] = doc
        doc.latest = true
      }

      if (isDocumentInSelectedMonth(doc)) {
        self.selectedYear.selectedMonth.documents.push(doc)
        increasePageNumber = true
      }
    }

    if (increasePageNumber) {
      self.selectedYear.selectedMonth.currentPage++
    }
  }

  function loadSelectedMonth() {
    const year = self.selectedYear.name
    const month = self.selectedYear.selectedMonth.value
    let doc

    self.selectedYear.selectedMonth.loadingData = true

    compensationService.getCompensationsByMonth({
      filters: self._getFilterTypeCodesToQuery(self._getCheckedFilters()),
      month: month,
      year: year,
      pageNumber: self.selectedYear.selectedMonth.currentPage + 1,
    }).then(function(httpData) {
      if (httpData.status === 404 || httpData.documents.length === 0) {
        self.selectedYear.selectedMonth.moreData = false
        self.selectedYear.selectedMonth.loadingData = false
      } else {
        doc = httpData.documents[0]

        doc.modDatetime = new Date(doc.modDate)
        if (isDocumentInSelectedMonth(httpData.documents[0])) {
          self.selectedYear.selectedMonth.loadingData = false
        }
        loadCompensations(httpData.documents)
      }

      if (self.selectedYear.selectedMonth.documents.length >= httpData.numFound) {
        self.selectedYear.selectedMonth.moreData = false
      }
    }, function(httpData) {
      console.log('Error getting compensation data')
    })
  }

  self._renderCompensations = function(resultFromApi) {
    self._initYears(resultFromApi)
    loadCompensations(resultFromApi.documents)
  }

  function init() {
    if (!compensationResult.error) {
      self._renderCompensations(compensationResult)
      compensationService
          .getRelevantAgentDocumentTypes()
          .then(self._setFilters)
    } else {
      setError(compensationResult.error)
    }
  }

  init()
}
