import { CrafterService } from '../../utils/crafter-service'
import { BENEFICIARY_SOURCE_OBJECT_TYPES, Beneficiary } from '../beneficiaries/classes/beneficiary'
import { TypeCodeValue } from '../beneficiaries/classes/type-code-value'
import { BENEFICIARY_ROLES, ASSIGNEE_ROLES, MISC_ROLES, ANNUITANT_ROLES } from '../beneficiaries/constants/ROLES'
import { IOrganizedParties } from '../beneficiaries/types'
import { IRoleEligibilityResult, IRoleIneligibilityRule } from '../roles/types'
import { RESTRICTED_CONTRACT_FLAGS } from './constants'

export const HOLDING_STATUS = { ACTIVE: '1' }
export class RolesPermissionService {

  static $inject = ['clientMeService', 'dateUtils']

  beneficiaryMetadata: any

  constructor(private clientMeService: any, private dateUtils: any) {

  }

  policyIneligibilityChecks: IRoleIneligibilityRule[] = [
    {
      fn: (policy: any): Boolean => policy.holdingStatusType?.tc !== HOLDING_STATUS.ACTIVE,
      desc: 'The policy is currently not inforce or active.',
    },
    {
      fn: (policy: any): Boolean => policy.jurisdiction === 'Massachusetts',
      desc: 'This policy was issued in Massachusetts.',
    },
    {
      fn: (policy: any): Boolean => ['3', '43'].includes(policy.qualifiedPlanType?.tc),
      desc: 'This annuity contract is a tax-sheltered or tax-deferred annuity.',
    },
    {
      fn: (policy: any): Boolean => policy.deathBenefitAmt > 500000,
      desc: 'The death benefit of this policy is more than $500,000.',
    },
    {
      fn: (policy: any): Boolean => policy.isAnnuity && policy.productType === 'Annuity Payout',
      desc: 'The annuity is in payout.',
    }, {
      fn: (policy: any): Boolean => this.beneficiaryMetadata.restrictedPolicyStatusCodes.includes(policy.policyStatusType?.tc),
      desc: 'This policy has additional status code restrictions preventing beneficiary changes.',
    },
  ]

  partyIneligibilityChecks: IRoleIneligibilityRule[] = [
    {
      fn: (parties: any): Boolean => Boolean(parties.assignees.find((assignee: Beneficiary) => assignee.roles?.find((role: TypeCodeValue) => role.tc === ASSIGNEE_ROLES.COLLATERAL_ASSIGNEE))),
      desc: 'This policy has a collateral assignee.',
    },
    {
      fn: (parties: any): Boolean => parties.owners.length > 1,
      desc: 'This policy has more than one owner.',
    },
    {
      fn: (parties: any): Boolean => parties.owners.length === 1 && parties.owners[0].partyTypeCode.tc !== '1',
      desc: 'The owner of this policy is an organization (e.g. trust or corporation).',
    },
    {
      fn: (parties: any): Boolean => parties.beneficiaries.filter((beneficiary: Beneficiary) => [BENEFICIARY_ROLES.PRIMARY, BENEFICIARY_ROLES.CONTINGENT].includes(beneficiary.role.tc) && beneficiary.partyTypeCode.tc !== '1').length > 0,
      desc: 'The primary and/or contingent beneficiary on this policy is an organization (e.g. trust or corporation).',
    },
    {
      fn: (parties: any): Boolean => Boolean(parties.beneficiaries.find((beneficiary: Beneficiary) => [BENEFICIARY_ROLES.SECOND].includes(beneficiary.role.tc))),
      desc: 'There is a second contingent beneficiary on this policy.',
    },
    {
      fn: (parties: any): Boolean => Boolean(parties.contacts.find((contact: Beneficiary) => [MISC_ROLES.GUARDIAN, MISC_ROLES.CONSERVATOR, MISC_ROLES.POWER_OF_ATTORNEY, MISC_ROLES.POWER_OF_ATTORNEY_FOR].includes(contact.role.tc))),
      desc: 'There is either a guardian, conservator, or power of attorney on this policy.',
    },
    {
      fn: (parties: any): Boolean => parties.beneficiaries.filter((beneficiary: Beneficiary) => beneficiary.isFlatAmount).length > 0,
      desc: 'A beneficiary on this policy has a flat amount share.',
    },
    {
      fn: (parties: any): Boolean => {
        const benes = parties.beneficiaries.filter((beneficiary: Beneficiary) => {
          if (beneficiary.address?.isClean) {
            return false
          }

          return beneficiary.address && beneficiary.address?.addressCountry?.tc !== '1'
        })

        return benes.length > 0
      },
      desc: 'This policy has a beneficiary not located in the USA.',
    },
    {
      fn: (parties: any): Boolean => Boolean(parties.annuitants.find((annuitant: Beneficiary) => [ANNUITANT_ROLES.JOINT_ANNUITANT, ANNUITANT_ROLES.CONTINGENT_ANNUITANT].includes(annuitant.role.tc))),
      desc: 'An annuitant on this policy is a joint or contingent annuitant',
    },
    {
      fn: (parties: any): Boolean => Boolean(parties.owners.find((owner: Beneficiary) => ['MA'].includes(owner.address.state))),
      desc: 'Owner resides in Massachusetts.',
    },
    {
      fn: (parties: IOrganizedParties, currentDate?: Date): Boolean => {
        const bensToCheck = [].concat(parties.owners)
        const benes = bensToCheck.filter((beneficiary: Beneficiary) => {
          if (!beneficiary?.address?.startDate) {
            return false
          }
          const days = this.dateUtils.dateDiffInDays(new Date(beneficiary.address?.startDate), currentDate)

          return (days <= 14)

        })

        return benes.length > 0
      },
      desc: 'An address on this policy has been changed within the last 14 days.',
    },
  ]

  clientFlagsIneligibilityCheck = (owner: any): string => {
    if (owner?.clientFlags?.length > 0) {
      return 'There are restrictions on this policy.'
    }

    return ""
  }

  contractFlagsIneligibilityCheck = (owner: any, policy: any): string[] => {
    const contractFlags: any = owner?.contractFlags ? owner.contractFlags : policy.contractFlags
    const restrictedContractFlags = (contractFlags?.filter((flag) => {
      return RESTRICTED_CONTRACT_FLAGS.includes(flag.tc) && policy.polNumber.includes(flag.pid)
    }) || []).map((flag: any) => flag.value || flag.description)

    return restrictedContractFlags
  }

  accountAgeCheck = (userAccount: any): string => {
    const days = this.dateUtils.dateDiffInDays(new Date(userAccount?.createdDate), new Date())

    if (days <= 14) return "The client’s online account was created within the last 14 days."

    return ""
  }

  clientCanManageBeneficiaries(policy: any, parties: any, beneficiaryMetadata: any): IRoleEligibilityResult {
    this.beneficiaryMetadata = beneficiaryMetadata

    const owner = this.clientMeService.partyInfo || parties.Owner[0]// partyInfo is the logged in user. When not found use the first owner on the policy.
    let ineligibleReasons: string[] = []
    const policyIsInEligible: Boolean = this.policyIneligibilityChecks.reduce((ineligibleResult: boolean, checkFn: IRoleIneligibilityRule): Boolean => {
      const checkFnResult = checkFn.fn(policy)

      if (!ineligibleResult && checkFnResult) ineligibleResult = true
      if (checkFnResult) ineligibleReasons.push(checkFn.desc)

      return ineligibleResult
    }, false)
    const partiesAreInEligible: Boolean = this.partyIneligibilityChecks.reduce((ineligibleResult: boolean, checkFn: IRoleIneligibilityRule): Boolean => {
      const checkFnResult = checkFn.fn(parties, new Date())

      if (!ineligibleResult && checkFnResult) ineligibleResult = true
      if (checkFnResult) ineligibleReasons.push(checkFn.desc)

      return ineligibleResult
    }, false)
    const hasIneligibleClientFlags: string = this.clientFlagsIneligibilityCheck(owner)
    const hasIneligibleContractFlags: string[] = this.contractFlagsIneligibilityCheck(owner, policy)
    const hasNewUserAccount: string = this.accountAgeCheck(owner)
    const ineligible: Boolean = policyIsInEligible || partiesAreInEligible || Boolean(hasIneligibleClientFlags) || hasIneligibleContractFlags.length > 0 || Boolean(hasNewUserAccount)

    if (hasIneligibleClientFlags) ineligibleReasons.push(hasIneligibleClientFlags)
    if (hasIneligibleContractFlags.length) ineligibleReasons = ineligibleReasons.concat(hasIneligibleContractFlags)
    if (hasNewUserAccount) ineligibleReasons.push(hasNewUserAccount)

    // console.log(ineligibleReasons)

    return { eligible: !ineligible, ineligibleReasons }
  }
}

