/* eslint-disable no-underscore-dangle */
import { IController, IPromise, IQService } from 'angular'
import { RoleRelationshipsService } from '../beneficiaries/relationships/role-relationships-service'
import { BeneficiariesDataProviderService } from '../beneficiaries/beneficiary-data-provider'
import { BENEFICIARY_ROLES } from '../beneficiaries/constants/ROLES'
import { Beneficiaries } from '../beneficiaries/classes/beneficiaries'
import { IAddressCountry, IAddressMininal } from '../address/types'
import { BENEFICIARIES_DIST_TYPES_WITH_TIPS, IGW_DISTRIBUTION_TYPECODES, USE_TRANSACTIONAL_BENEFICIARY_ENDPOINT } from '../beneficiaries/beneficiary-constants'
import { BENEFICIARY_SOURCE_OBJECT_TYPES, Beneficiary } from '../beneficiaries/classes/beneficiary'
import { IBeneficiaryCategory } from '../beneficiaries/types'
import { CS_PARTY_TYPECODES, CS_PARTY_TYPES } from '../beneficiaries/constants/BENEFICIARY_OTHER_OPTIONS'
import { TypeCodeValue } from '../beneficiaries/classes/type-code-value'
import { HookResult, Transition, TransitionService } from 'angular-ui-router'
import { ModalService } from '../../components/modal/modal-service'
import { BeneficiaryShare } from '../beneficiaries/classes/share'

export interface INotificationInfo {
  firstName: string
  lastName: string
  email: string
  carrcd: string
}

export interface ISaveResult {
  success: boolean
  errorMessage: string
}

const SECTION_INDECIES = {
  PRIMARY: 0,
  CONTINGENT: 1,
  FINAL: 2,
}

const BENEFICARY_SECTIONS = {
  [BENEFICIARY_ROLES.PRIMARY]: SECTION_INDECIES.PRIMARY,
  [BENEFICIARY_ROLES.CONTINGENT]: SECTION_INDECIES.CONTINGENT,
  [BENEFICIARY_ROLES.FINAL]: SECTION_INDECIES.FINAL,
}
export class ClientBeneficiariesController implements IController {
  static $inject: any = ['$q', 'CONSTANTS', 'roleRelationshipsService', 'beneficiariesDataProvider', 'clientUtils', 'policySummary', 'whoAmI', 'clientResults', 'modalService']

  originalData: Beneficiaries[] = []
  pid: string
  sections: IBeneficiaryCategory[] | Beneficiaries[] = []
  showAllSections: boolean = false
  acordCountries: IAddressCountry
  distTypeTypeCodes: any
  canSubmitData: boolean = false
  organizedParties: any

  private categoryStates: any[] = []
  private _deregisterFunctions: any[] = []

  constructor(private $q: IQService, CONSTANTS: any, private roleRelationshipsService: RoleRelationshipsService, private beneficiariesDataProvider: BeneficiariesDataProviderService, clientUtils: any, public policySummary: any, public whoAmI: any, clientResults: any, private modalService: ModalService) {
    this.distTypeTypeCodes = BENEFICIARIES_DIST_TYPES_WITH_TIPS
    this.organizedParties = clientUtils.organizeClientDetails(clientResults, CONSTANTS.roleOrders)
  }

  /**
   * Initializes the beneficiary categories to display on the "list" UI
   */
  private initializeSections(): void {
    this.pid = this.policySummary.polNumber

    this.sections[SECTION_INDECIES.PRIMARY] = {
      rid: BENEFICIARY_ROLES.PRIMARY,
      title: 'Primary Beneficiaries',
      subTitle: '',
      pid: this.pid,
    }
    this.sections[SECTION_INDECIES.CONTINGENT] = {
      rid: BENEFICIARY_ROLES.CONTINGENT,
      title: 'Contingent Beneficiaries',
      subTitle: 'Contingent Beneficiaries receive the Death Benefit if no Primary Beneficiary survive the Insured.',
      pid: this.pid,
    }
    // Client Bene Editor does not support Second Contingent Beneficiaries
    // this.sections[2] = {
    //   rid: BENEFICIARY_ROLES.SECOND,
    //   title: 'Second Contingent Beneficiaries',
    //   pid: this.pid,
    // }
    this.sections[SECTION_INDECIES.FINAL] = {
      rid: BENEFICIARY_ROLES.FINAL,
      title: 'Final Beneficiaries',
      subTitle: 'If neither Primary nor Contingent Beneficiaries survive the Insured, the proceeds shall be paid to the following:',
      pid: this.pid,
    }
  }

  /**
   * Make the calls for each of the beneficiary role types. (e.g. 34, 26, and 1012300003)
   *
   * Then it performs a check to see if there are any beneficiaries with specific dollar amount distributions.
   * If so then the `this.showAllSections` is set to `false`, and a message is displayed to the user. Otherwise,
   * the flag is set to `true`.
   *
   * If `this.showAllSections` flag is `true`, then backup of the data is made, so a complete undo can be
   * performed during the `REVIEW` view state.
   *
   * @returns Promise<void>
   */
  private async getBeneficiariesForEachClass(associatedParties): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/space-before-function-paren
    return this.$q.all(this.sections.map(async (section: IBeneficiaryCategory): Promise<Beneficiaries> => this.beneficiariesDataProvider.getBeneficiaries(section, associatedParties)))
      .then((beneficiaries: Beneficiaries[]) => {
        this.sections = beneficiaries

        // Making a backup of the origin data from when the route loaded. This will be used for
        // reverting all changes made via the review state, and to determine if there are changes to submit.
        this.originalData = this.sections.map((section: Beneficiaries) => this.beneficiariesDataProvider.deepCloneBeneficiaries(section))

        this.showAllSections = true // TODO: Not sure why we needed this.
      })
  }

  private p_currentBeneficiary: Beneficiary = null
  private p_backupCurrentBeneficiary: Beneficiary = null

  public get currentBeneficiary(): Beneficiary {
    return this.p_currentBeneficiary
  }

  public get backupCurrentBeneficiary(): Beneficiary {
    return this.p_backupCurrentBeneficiary
  }

  /**
   *
   * @param rid
   * @param bene
   */
  public setCurrentBeneficiary(rid?: string, bene?: Beneficiary, equalShares?: boolean): void {
    if (!rid) {
      this.p_backupCurrentBeneficiary = null
      this.p_currentBeneficiary = null
    } else {
      this.p_backupCurrentBeneficiary = bene ? new Beneficiary(rid, bene) : null
      this.p_currentBeneficiary = bene ? new Beneficiary(rid, bene) : new Beneficiary(rid, null , BENEFICIARY_SOURCE_OBJECT_TYPES.NEW_PARTY_OPTION)

      if(this.p_currentBeneficiary.isNew) {
        const tmpRole: any = { partyTypeCode: CS_PARTY_TYPECODES[CS_PARTY_TYPES.PERSON] }

        if(equalShares) {
          tmpRole.distribution = IGW_DISTRIBUTION_TYPECODES.EQUAL_SHARE
        } else {
          tmpRole.distribution = IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE
        }

        this.p_currentBeneficiary.shareDistribution = new BeneficiaryShare(tmpRole, rid)
      }
    }
  }

  /**
   * Save the changes made while editing the full beneficairy record.
   *
   * @returns ISaveResult
   */
  public saveBeneficiaryChanges(): ISaveResult {
    const category: Beneficiaries = this.sections[BENEFICARY_SECTIONS[this.p_currentBeneficiary.roleId]] as Beneficiaries
    const saveResult: ISaveResult = { success: false, errorMessage: '' }

    this.p_currentBeneficiary.isDirty = true // This to let screen 1, know that screen 2 apply changes to the current beneficiary

    if (this.p_currentBeneficiary.isNew) {
      category.beneficiaries.push(this.p_currentBeneficiary)

      saveResult.success = true
    } else {
      const foundBeneIndex: number = category.beneficiaries.findIndex((bene: Beneficiary) => bene.id === this.p_currentBeneficiary.id)

      if (foundBeneIndex > -1) {
        category.beneficiaries[foundBeneIndex] = this.p_currentBeneficiary
        saveResult.success = true
      } else {
        saveResult.errorMessage = 'Save failed; No matching beneficiary found with id of ' + this.p_currentBeneficiary.id
      }

    }

    return saveResult
  }

  /**
   * Rolls back all changes made to the policies beneficiaries.
   */
  public revertAllChanges(): void {
    this.sections = this.originalData.map((section: Beneficiaries) => this.beneficiariesDataProvider.deepCloneBeneficiaries(section))
  }

  /**
   * ngClick handler for the "Submit Changes" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.REVIEW`.
   *
   * Sets the `BeneficiariesComponent.viewState` to `BENEFICIARY_VIEWS.LOADING`, and then calls the
   * `BeneficiariesDataProvider.submitBeneficiaries` method with the current `pid`, and all of the
   * categories beneficiary data.
   *
   * @returns a Promise<void>
   */
  async submitChanges(): Promise<any> {
    // this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.LOADING]
    const notificationInfo: INotificationInfo = {
      firstName: this.whoAmI.firstName,
      lastName: this.whoAmI.lastName,
      email: this.whoAmI.emailAddress?.[0]?.addrLine,
      carrcd: this.policySummary.carrierCode,
    }

    const payload = this.beneficiariesDataProvider.buildPayload(this.pid, this.sections as Beneficiaries[], this.originalData, new Date(), true, notificationInfo)

    const tmp = this.beneficiariesDataProvider.getAllPartiesInvolved(this.sections as Beneficiaries[], this.originalData)

    payload.beforeSnapShot = JSON.stringify(tmp.beforeData)
    payload.afterSnapShot = JSON.stringify(tmp.afterData)

    return this.beneficiariesDataProvider.submitBeneficiaries(payload, USE_TRANSACTIONAL_BENEFICIARY_ENDPOINT)
  }


  // get isValidPayload(payload): boolean {
  //   if (!this.payload) return false

  //   const clientChanges: number = this.payload.clientChanges.add.length + this.payload.clientChanges.update.length
  //   const phoneChanges: number = this.payload.phoneChanges.add.length + this.payload.phoneChanges.update.length + this.payload.phoneChanges.delete.length
  //   const roleChanges: number = this.payload.roleChanges.add.length + this.payload.roleChanges.update.length + this.payload.roleChanges.delete.length

  //   return clientChanges + phoneChanges + roleChanges > 0
  // }

  /**
   *
   * @param categoryIndex
   * @param hasChanges
   * @returns
   */
  runChecks(categoryIndex: number, categoryIsValid: boolean, categoryHasChangedData: boolean): void {

    this.categoryStates[categoryIndex] = { categoryIndex, categoryIsValid, categoryHasChangedData, shouldSubmit: categoryIsValid && categoryHasChangedData }

    if (this.categoryStates.length !== 3) return

    this.canSubmitData = this.categoryStates.some((state: any) => state.categoryHasChangedData) && this.categoryStates.every((state: any) => state.categoryIsValid)

    // console.table(this.categoryStates)
  }
  /**
   * @deprecated
   * @returns
   */
  hasChanges(): boolean {
    return Boolean(this.sections.find((section: Beneficiaries) => section.isDirty))
  }

  openWarningDialog(): any {
    this.modalService.openComponent('warningModalComponent', {
      windowClass: 'warning-modal',
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
    })

    return this.modalService.modalInstance.result
  }

  validateAddress(bene: Beneficiary): any {
    return this.beneficiariesDataProvider.validate(bene.address).then((result) => {
      // console.log(result)
      this.modalService.openComponent('addressValidation', {
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          standardizedAddress: () => result,
          currentAddress: () => bene.address,
          errorMessage: () => result,
        }
      })

      return this.modalService.modalInstance.result
    }).then((result) => result)
  }

  // temp() {

  //   console.log('CSSB MFA Check', this.$state.$current.name, this.$state.$current.url)

  //   return mfaDataProvider.checkMfaVerification('mfaCssbVerified')
  //     .then((verified: boolean) => {
  //       if (verified) return true

  //       return $state.target('mfa.send-code', { firstTimeMfaUser: true, redirectState: {} })
  //     })
  //     .catch((verified: boolean) => console.error(verified))
  // }

  private initializeData() {
    this.initializeSections()

    this.roleRelationshipsService.load()
      .then(() => this.beneficiariesDataProvider.getAccordCountries().then((countries) => this.acordCountries = countries))
      .then(async() => this.getBeneficiariesForEachClass([]))
    // .then(() => console.log(this.sections))

  }

  $onInit(): void {
    this.initializeData()
  }

  $onDestroy(): void {
    this._deregisterFunctions.forEach((fn) => fn())
  }
}
