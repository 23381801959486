/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */

import { IDeferred, IHttpService, ILocationService, IPromise, IQService, isNumber } from "angular"
import { HookResult, StateObject, StateParams, StateService, TargetState, Transition, TransitionService, UrlService } from "angular-ui-router"
import { MfaDataProvider } from "../mfa/mfa-data-provider"
import { MFA_TOKEN_TYPES, MFA_UI_ROUTES } from "../mfa"

const MFA_WHITELIST_ROUTES: string[] = [
  'mfa.check',
  'mfa.send-code',
  'mfa.submit-code',
  'login-client',
]

const DEEP_LINK_ROUTES: string[] = [
  'alerts',
  'client.me',
  'epay.policy.payment',
  'myPolicy',
  'policy.requirements',
]

const EPAY: string = 'epay'
const SEARCH_RESULT: string = 'search.result'

export class AuthModuleRunner {
  static $inject = [
    '$q', '$rootScope', '$http', '$state', '$stateParams', 'Idle', 'authService', 'deviceUtils',
    '$transitions', 'navigationStack', 'CONSTANTS',
    '$location', 'mfaDataProvider', 'configService', '$urlService'
  ]

  private deregisterHooks: Function[] = []

  constructor(private $q: IQService, private $rootScope: any, private $http: IHttpService, private $state: StateService, private $stateParams: StateParams,
    private Idle: any, private authService: any, private deviceUtils: any,
    private $transitions: TransitionService, private navigationStack: any, private CONSTANTS: any,
    private $location: ILocationService, private mfaDataProvider: MfaDataProvider,
    private configService: any, private $urlService: UrlService) {
    this.initializeRootScope()
    this.configureHttpService(this.authService.getSessionID())
    this.configureTransitionHandlers()
  }

  private configureTransitionHandlers() {
    this.deregisterHooks.push(this.$transitions.onBefore({}, (trans: Transition) => this.defaultTransitionHandler(trans)))
    this.deregisterHooks.push(this.$transitions.onError({}, (trans: Transition) => console.error(trans.error())))
    this.deregisterHooks.push(this.$transitions.onSuccess({}, (trans: Transition) => this.mobileRoutingTransitionHandler(trans)))
    // this.deregisterHooks.push(this.$transitions.onError({ to: (state: StateObject) => ROUTER_WHITELIST_PATTERNS.includes(state.name) }, this.whiteListTransitionHandler))
  }

  /**
   * Private Properties
   */
  private isAuthenticated: boolean = this.authService.isAuthenticated()
  private isClientPortalUser: boolean = this.authService.isARealClient()
  private isClientPortalServer: boolean = this.configService.serverMode === 'client'

  /**
   * Adds a few properties to $rootScope
   */
  private initializeRootScope(): void {
    this.$rootScope.idleEvents = []
    this.$rootScope.auth = this.authService
    this.$rootScope.isClientView = this.authService.isClientView()
    this.$rootScope.isAgentView = this.authService.isAgentView()
  }

  /**
   * Configures the $http service with some default common headers.
   *
   * @param sessionID
   */
  private configureHttpService(sessionID) {
    if (sessionID) {
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${sessionID}`

      // disable IE ajax request caching
      this.$http.defaults.headers.common['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT'
      // extra
      this.$http.defaults.headers.common['Cache-Control'] = 'no-cache'
      this.$http.defaults.headers.common['Pragma'] = 'no-cache'
    }
  }


  /**
   * Returns true if the provided routeName is included in the ROUTER_WHITELIST.
   *
   * @param routeName
   * @returns boolean
   */
  private isMfaWhitelistedRoute(routeName: string): boolean {
    return MFA_WHITELIST_ROUTES.includes(routeName)
  }

  /**
   * Returns true if the provided routeName start with EPAY.
   *
   * @param routeName
   * @returns boolean
   */
  private isInitialPayment(routeName: string): boolean {
    return routeName?.startsWith(EPAY)
  }

  /**
   * Returns true if the app is running as a Client Portal.
   *
   * @param routeName
   * @returns boolean
   */
  private shouldHandleDeepLinks(routeName: string): boolean {
    return this.isClientPortalServer && !this.isAuthenticated && DEEP_LINK_ROUTES.includes(routeName)
  }

  /**
   * TODO: What does this actually do?
   *
   * @param routeName
   */
  private configureFooter(routeName: string) {
    // Sets default footer unless toState matches a policy route, epay route or their children.
    if (/^(policy|epay)(\.)*/.test(routeName)) {
      this.$rootScope.$broadcast('updateFooter')
    } else {
      this.$rootScope.carrierName = 'The Penn Mutual Life Insurance Company'
    }
  }

  /**
   *
   * @param toState
   * @returns
   */
  private handleUnauthenticatedAccessToRoutes(toState: StateObject) {
    if (this.shouldHandleDeepLinks(toState.name)) {
      console.info('Detected allowed deep link attempt by unauthenticated user')

      window.location.href = `${this.CONSTANTS.authProxyURL}/client?startUrl=${window.encodeURIComponent(this.$location.absUrl())}`
      return false
    } else if (toState.data?.secure) {
      console.log('User is not authenticated.')
      this.$rootScope.$evalAsync(function () {
        this.authService.logout('client with toState info', toState)
      })

      return false
    }

    return true
  }


  /**
   * Resolves false if the MFA feature flag is false.
   *
   * As an extra check, see if the user is a Client Portal user.
   * (NOTE: This isn't really need because the MFA flag is specific to Client Portal).
   *
   * If MFA is enabled then make the async call to verify if the logged in user has
   * a trusted browser, and that there is a sever-side session token in place.
   *
   * If so, the promise will resolve true if:
   *    - the routeName is not whitelisted
   *    - and, the routename does not start with EPAY
   *    - and, verified returns as false,
   *
   * @param routeName
   * @returns IPromise<boolean>
   */
  private async performMfaVerification(trans: Transition): Promise<boolean | TargetState> {
    const deferred: IDeferred<boolean | TargetState> = this.$q.defer()
    const routeName: string = trans.$to().name

    void this.mfaDataProvider.checkMfaVerification(MFA_TOKEN_TYPES.MFA_VERIFIED, { returnState: trans.$to().name, returnUrl: trans.$to().url.pattern })
      .then((verified: boolean) => {
        if (!this.isMfaWhitelistedRoute(routeName) && !this.isInitialPayment(routeName) && !verified) {
          const returnUrl = this.$location.url()
          const redirectState: TargetState = trans.router.stateService.target(MFA_UI_ROUTES.CHECK, { redirect_url: returnUrl }, { location: true })

          return deferred.resolve(redirectState)
        } else if (verified || this.isMfaWhitelistedRoute(routeName)) {
          return deferred.resolve(true)
        }
      })

    return deferred.promise
  }

  private enableIdleMonitor() {
    if (!this.Idle.running()) {
      this.Idle.watch()
    }
  }

  private defaultTransitionHandler(trans: Transition): HookResult {
    const deferred: any = this.$q.defer()

    console.log(`defaultTransitionHandler: fromState: ${trans.$from().name} toState: ${trans.$to().name}`)


    this.configureFooter(trans.$to().name)

    if (this.isAuthenticated) {
      this.enableIdleMonitor()

      if (this.isClientPortalUser && this.configService.features.mfaEnabled) {
        return this.performMfaVerification(trans)
      } else {
        // Insight or no MFA
        const fromState = trans.$from()
        const toState = trans.$to()

        if (fromState.name === 'policy' && toState.name === 'search.result') {
          const params = trans.paramsChanged()
          // console.log('>>>', this.$state.href(toState.name, toState.params))
          // console.log('>>>', this.$state.href(fromState.name, fromState.params))
          // console.log('>>>', trans.paramsChanged())

          if (params.c) {
            const redirectState: TargetState = trans.router.stateService.target('search.result', { c: '', nonUrlCriteria: '' }, { location: true })

            return redirectState
          } else {
            return true
          }
        } else {
          return true
        }
      }

    } else {
      return this.handleUnauthenticatedAccessToRoutes(trans.$to())
    }
  }

  /**
   *
   * @param trans
   * @returns
   */
  private ssnProtectionTransitionHandler(trans: Transition): HookResult {
    console.log(`ssnProtectionTransitionHandler: toState: ${trans.$to().name} fromState: ${trans.$from().name},`)

    const deferred: IDeferred<boolean | TargetState> = this.$q.defer()

    if (isNumber(parseInt(trans.params().c, 10))) {
      const redirectState: TargetState = trans.router.stateService.target('search.result', {
        t: 'result',
        c: '',
      })

      deferred.resolve(redirectState)
    } else {
      deferred.resolve(true)
    }

    return deferred.promise as HookResult
  }

  /**
   * TODO: make this it's own transition hook.
   *
   * @param toState
   * @param toParams
   * @param fromState
   * @param fromParams
   */
  private mobileRoutingTransitionHandler(trans: Transition) {
    const toState: StateObject = trans.$to()
    const fromState: StateObject = trans.$from()

    // console.log(toState.name, toState.parameters(), this.$state.href(toState.name, toState.parameters(), { absolute: false }))
    const toUrl: string = this.$state.href(toState.name, toState.parameters())
    const lastObj: any = this.navigationStack.getLast()
    const lastUrl: string = this.$state.href(lastObj.state, lastObj.params, { absolute: false })
    const sameUrl: boolean = lastUrl === toUrl

    console.log(toState.name, 'transition successful.')

    const tmp: any = {}
    if (this.deviceUtils.isMobilePlatform()) {
      if (sameUrl) {
        this.navigationStack.pop()
      } else {
        const state: string = lastUrl === null ? toState.name : fromState.name
        const params: any[] = lastUrl === null ? toState.parameters() : fromState.parameters()

        this.navigationStack.push(state, params)
      }
    }
  }

  // private whiteListTransitionHandler(trans: Transition): HookResult {
  //   console.log('whiteListTransitionHandler', trans)
  //   return true
  // }

  // private deeplinkTransitionHandler(trans: Transition): HookResult {
  //   console.log('whiteListTransitionHandler', trans)
  //   return true
  // }

}
