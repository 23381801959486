/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDropdownEntry } from '../components/dropdown/dropdown.component'
import { IUserSettingResponse } from './user-settings.service'

/* eslint-disable no-shadow */
export enum NotificationCategory {
  LNBA = 'lnba',
  INFORCE = 'inforce'
}

export enum NotificationChannel {
  EMAIL = 'email',
  SMS = 'sms',
  DAILY_EMAIL = 'dailyEmail',
  WEEKLY_EMAIL = 'weeklyEmail',
  DAILY_SMS = 'dailySMS',
  WEEKLY_SMS = 'weeklySMS',
}

export enum NotificationTypes {
  INFORCE_DAILY_EMAIL = 'INFORCE_DAILY_EMAIL',
  INFORCE_DAILY_SMS = 'INFORCE_DAILY_SMS',
  INFORCE_WEEKLY_EMAIL = 'INFORCE_WEEKLY_EMAIL',
  INFORCE_WEEKLY_SMS = 'INFORCE_WEEKLY_SMS',
  LNBA_EMAIL = 'LNBA_EMAIL',
  LNBA_SMS = 'LNBA_SMS'
}

export interface INotificationSettings {
  [NotificationCategory.LNBA]: {
    [NotificationChannel.EMAIL]: boolean
    [NotificationChannel.SMS]: boolean
  }
  [NotificationCategory.INFORCE]: {
    notificationTime: IDropdownEntry
    [NotificationChannel.DAILY_EMAIL]: boolean
    [NotificationChannel.WEEKLY_EMAIL]: boolean
    [NotificationChannel.DAILY_SMS]: boolean
    [NotificationChannel.WEEKLY_SMS]: boolean
  }
}

export const notificationTimes: IDropdownEntry[] = [
  // { label: '6:00 AM', value: 6 },
  { label: '7:00 AM', value: 7 },
  { label: '8:00 AM', value: 8 },
  { label: '9:00 AM', value: 9 },
  { label: '10:00 AM', value: 10 },
  { label: '11:00 AM', value: 11 },
  { label: '12:00 PM', value: 12 },
  { label: '1:00 PM', value: 13 },
  { label: '2:00 PM', value: 14 },
]

export enum DELIVERY_ENDPOINT {
  MFA_PHONE = 'MFA_PHONE',
  WEB_EMAIL = 'WEB_EMAIL'
}

export const NOTIFICATION_TYPE_MAP = {
  [NotificationChannel.EMAIL]: { type: NotificationCategory.LNBA, frequency: 'ad-hoc-new-business', channel: 'email', endpoint: DELIVERY_ENDPOINT.WEB_EMAIL },
  [NotificationChannel.SMS]: { type: NotificationCategory.LNBA, frequency: 'ad-hoc-new-business', channel: 'sms', endpoint: DELIVERY_ENDPOINT.MFA_PHONE },
  [NotificationChannel.DAILY_EMAIL]: { type: NotificationCategory.INFORCE, frequency: 'daily-existing-business', channel: 'email', endpoint: DELIVERY_ENDPOINT.WEB_EMAIL },
  [NotificationChannel.WEEKLY_EMAIL]: { type: NotificationCategory.INFORCE, frequency: 'weekly-existing-business', channel: 'email', endpoint: DELIVERY_ENDPOINT.WEB_EMAIL },
  [NotificationChannel.DAILY_SMS]: { type: NotificationCategory.INFORCE, frequency: 'daily-existing-business', channel: 'sms', endpoint: DELIVERY_ENDPOINT.MFA_PHONE },
  [NotificationChannel.WEEKLY_SMS]: { type: NotificationCategory.INFORCE, frequency: 'weekly-existing-business', channel: 'sms', endpoint: DELIVERY_ENDPOINT.MFA_PHONE },
}

export interface IUserSettingPayload {
  frequency: string
  channel: string
  value: {
    deliveryEndpoint: DELIVERY_ENDPOINT
    hoursOfDay?: number[]
  }
}

export class NotificationSettings implements INotificationSettings {
  [NotificationCategory.LNBA]: { email: boolean; sms: boolean } = { email: false, sms: false };
  [NotificationCategory.INFORCE]: { notificationTime: IDropdownEntry; dailyEmail: boolean; weeklyEmail: boolean; dailySMS: boolean; weeklySMS: boolean } = {
    notificationTime: {label: '', value: -1},
    dailyEmail: false,
    weeklyEmail: false,
    dailySMS: false,
    weeklySMS: false,
  }

  private get updatableChannels(): NotificationChannel[] {
    return Object.entries(this.inforce).filter(([key, value]: [key: string, value: boolean | IDropdownEntry]) => {
      if (key === 'notificationTime') return false

      return value
    }).map(([key]: [key: string, value: boolean]) => key as NotificationChannel)
  }

  /**
   * Returns the time to display in the notification time dropdown.
   *
   * @param userSettingsResponse
   * @returns
   */
  private resolveDeliveryTime(userSettingsResponse: IUserSettingResponse) {
    const r = Object.values(userSettingsResponse)
      .map(v => v?.value?.hoursOfDay)
      .reduce((acc: number[], item: number[]) => {
        if (item) acc = acc.concat(item)

        return acc
      }, [])
    const f = r.filter((a, b) => r.indexOf(a) === b)
    const t = f[0]

    if (!t) return { label: '', value: -1 }

    const o = notificationTimes.find((d) => d.value === t)

    return o ?? { label: '', value: -1 }
  }

  /**
   * Parses the settings returned by getSettings into the data structure implemented in the UI.
   *
   * @param settings
   * @returns
   */
  public parse(settings: any): void {
    if (!settings) return

    this[NotificationCategory.LNBA].email = Boolean(settings[NotificationTypes.LNBA_EMAIL])
    this[NotificationCategory.LNBA].sms = Boolean(settings[NotificationTypes.LNBA_SMS])
    this[NotificationCategory.INFORCE].dailyEmail = Boolean(settings[NotificationTypes.INFORCE_DAILY_EMAIL])
    this[NotificationCategory.INFORCE].dailySMS = Boolean(settings[NotificationTypes.INFORCE_DAILY_SMS])
    this[NotificationCategory.INFORCE].weeklyEmail = Boolean(settings[NotificationTypes.INFORCE_WEEKLY_EMAIL])
    this[NotificationCategory.INFORCE].weeklySMS = Boolean(settings[NotificationTypes.INFORCE_WEEKLY_SMS])
    this[NotificationCategory.INFORCE].notificationTime = this.resolveDeliveryTime(settings)
  }

  public getPayload(setting: NotificationChannel): IUserSettingPayload {
    const { type, frequency, channel, endpoint }: { type: string; frequency: string; channel: string; endpoint: DELIVERY_ENDPOINT } = NOTIFICATION_TYPE_MAP[setting]
    const payload: IUserSettingPayload = {
      frequency,
      channel,
      value: {
        deliveryEndpoint: endpoint,
      },
    }

    if (type === NotificationCategory.INFORCE) {
      payload.value.hoursOfDay = [this.inforce.notificationTime.value]
    }

    return payload
  }

  /**
   * getUpdatePayload
   * ----------------
   * Creates a payload for when the notificationTime has been changed.
   *
   * It finds all of the updateable inforce settings, and creates an array IUserSettingPayload
   * objects to be sent to the middle tier.
   *
   */
  public getUpdatePayload(): IUserSettingPayload[] {
    const tmp: IUserSettingPayload[] = this.updatableChannels.map((setting: NotificationChannel) => this.getPayload(setting))

    return tmp
  }
}
