import { PERMISSION_IS_ADVISOR, ROLE_AGENT, ROLE_ASSISTANT, ROLE_FIELD_MGMT } from "../widgets/navigation-header/controller"

export class UserSettingsRunner {
  static $inject = ['routerHelper']

  constructor(routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates() {
    const userSettingsRouteConfig = {
      state: 'user-settings',
      config: {
        abstract: true,
        url: '/user-settings',
        template: '<ui-view></ui-view>',
        resolve: {

        },
      },
    }

    const notificationRouteConfig = {
      state: 'user-settings.notifications',
      config: {
        url: '/notifications',
        component: 'notificationsComponent',
        resolve: {},
        onEnter: ['$rootScope', '$state', 'configService', 'authService', function($rootScope, $state, configService, authService) {
          const session = authService.getCurrentSession()
          const isHoUser = authService.isHomeOfficeUser()
          const hoNotPermitted = !(isHoUser && (session.canAccess.canManageUserSettings || session.canAccess.canViewUserSettings))
          const isNotAgentOrAssistant = !authService.checkRoles([ROLE_AGENT, ROLE_ASSISTANT, ROLE_FIELD_MGMT])
          const isNotAdvisor = !authService.matchAnyPermission([PERMISSION_IS_ADVISOR])

          // console.log('>>>>', configService.features.showNotifications, (configService.features.showNotifications && (isNotAgentOrAssistant && hoNotPermitted && isNotAdvisor)))
          if (!configService.features.showNotifications || (configService.features.showNotifications && (isNotAgentOrAssistant && hoNotPermitted && isNotAdvisor))) {
            $state.go('search.result')
            return
          }

          const pageTitle = 'Notifications Settings'

          $rootScope.pageTitle = pageTitle
          $rootScope.isPopupVisible = false
          $rootScope.state = 'policy-user-settings-form'
        }],
      },
    }

    return [
      userSettingsRouteConfig,
      notificationRouteConfig,
    ]
  }
}
