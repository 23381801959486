/* eslint-disable no-underscore-dangle */
import { Beneficiaries } from './classes/beneficiaries'
import { Beneficiary, BENEFICIARY_SOURCE_OBJECT_TYPES } from './classes/beneficiary'
import { ClientRoles } from './classes/client-roles'
import { TypeCodeValue } from './classes/type-code-value'
import { RoleChangeReponse } from './classes/role-change-response'
import { RolesSnapshot } from './types'
import { deepArrayOrObjectCopy, deepCompareObjects } from '../../utils/utils'
import { toDateString } from '../../utils/date-utils'
import { INotificationInfo } from '../client-bene-edit/client-bene.controller'
import { AddressValidationResult, IAddressMininal } from '../address/types'
import { IPromise } from 'angular'
import { LoggingService } from '../../utils/logging'
/**
 * Provides data to the Beneficiaries controller
 */
export class BeneficiariesDataProviderService {

  static $inject: string[] = ['CONSTANTS', 'utils', '$q', '$http', 'policyUtils', 'clientUtils', 'loggingService']

  private _countryTypeCodes: TypeCodeValue[] | null = null

  constructor(private CONSTANTS, private utils, private $q: angular.IQService, private $http,
    private policyUtils, private clientUtils, private loggingService: LoggingService) { }

  /**
   *
   * @param roles
   * @param prop
   * @param value
   * @returns
   */
  _isInRole(roles: any, prop: string, value: any): boolean {
    return Boolean(roles.find((role) => role[prop] === value))
  }

  /**
   *
   * @param partyList
   * @returns
   */
  _mapAndReduceParties(partyList: any[]): any[] {
    return partyList.reduce((acc, party) => {
      if (!this._isInRole(party.roles, 'tc', '32')) {
        acc.push(party)
      }
      return acc
    }, []).map((party) => {
      return {
        fullName: party.fullName,
        id: party.id,
        partyType: party.partyType,
        relationship: party.benerelationship,
        isAssociatedParty: true,
      }
    })
  }

  deepCloneBeneficiary(section: any, bene: Beneficiary): Beneficiary {
    return new Beneficiary(section.rid, bene, BENEFICIARY_SOURCE_OBJECT_TYPES.BENEFICIARY_INSTANCE)
  }

  /**
   * Returns a new instance of the `Beneficiaries` class, including
   * new instances of `Beneficiary` as required on the `beneficiaries`
   * property.
   *
   * NOTE: This fuction exists because we can't export classes without
   *       implementing WebPack or Parcel.
   *
   * @param sections
   */
  deepCloneBeneficiaries(section: any): Beneficiaries {
    const final: Beneficiaries = new Beneficiaries(section.title, section.subTitle, section.pid, section.rid, section.beneficiaries, section.associatedParties)

    final.beneficiaries = final.beneficiaries.map((bene: any) => new Beneficiary(section.rid, bene, BENEFICIARY_SOURCE_OBJECT_TYPES.BENEFICIARY_INSTANCE))

    return final
  }

  // deepCompareBeneficiaries(beforeChanges: Beneficiaries, afterChanges: Beneficiaries) {
  //   const changes = beforeChanges.reduce(() => {

  //   }, {add: [], update: [], delete: []})

  // }

  /**
   *
   * @param pid
   * @param roles
   * @returns
   */
  getPolicyRoles(pid: string, roles: string[]) {
    let url = `${this.CONSTANTS.apiRoot}policy/${pid}/roles`

    roles.forEach((role: any) => {
      url = this.utils.appendURLParameter(url, 'role', role)
    })

    return this.$http.get(url)
      .then((result: any) => result?.data?.parties ?? [])
      .catch((err: any) => {
        throw err
      }) // Bubble the error upwards.
  }

  /**
   * Returns a `Promise` to return an array of Beneficiary objects
   *
   * NOTE: The params are sent as an object and plucked out by JS.
   *
   * @param param0
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getBeneficiaries({ title, subTitle, pid, rid }, parties): Promise<Beneficiaries> {
    return this.getPolicyRoles(pid, [rid])
      .then((policyRoles: any[]) => this.clientUtils.expandParties(policyRoles))
      .then((beneRoles: any[]) => beneRoles.map((bene: any): Beneficiary => new Beneficiary(rid, bene, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)))
      .then((beneficiaries: Beneficiary[]): Beneficiaries => new Beneficiaries(title, subTitle, pid, rid, beneficiaries, parties))
  }

  getAllRoles(pid, covertToBeneObjects = true) {
    return this.getPolicyRoles(pid, [])
      .then((parties) => this.clientUtils.organizeClientDetails({ parties }))
      .then((parties) => {
        if (covertToBeneObjects) {
          Object.entries(parties).forEach(([key, data]) => {

            if (key === 'mailingOwner') {
              parties.mailingOwner = new Beneficiary('', data, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
            } else {
              parties[key] = (data as any).map((party) => party instanceof Beneficiary ? party : new Beneficiary('', party, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY))
            }

          })
          parties.allRoles = parties.contacts
          parties.contacts = parties.contacts.filter((party) => !party.isBeneficiary)
        }

        return parties
      })
  }

  /**
   *
   * @param partyIds
   * @param rid
   * @returns
   */
  getClientRolesByOwner(ownerIds: string[]): ClientRoles {
    let url = `${this.CONSTANTS.apiRoot}client/roles`

    ownerIds.forEach((partyId: string) => {
      url = this.utils.appendURLParameter(url, 'id', partyId)
    })

    return this.$http.get(url).then((result) => new ClientRoles(result?.data?.parties ?? []))
  }

  getRelationships(): any {
    const url = '/crafter/assets/misc/beneficiary-relationships.json'

    return this.$http.get(url)
      .then((result) => {
        const partyRelationships = {}

        Object.entries(result.data).forEach(([party, relations]) => {
          partyRelationships[party] = (relations as any[]).sort((a, b) => {
            if (a.value > b.value) {
              return 1
            }
            if (a.value < b.value) {
              return -1
            }
            return 0
          })
        })

        return partyRelationships
      })
  }

  getAccordCountries() {
    const url = '/crafter/assets/misc/acord-countries.json'

    if (this._countryTypeCodes === null) {
      return this.$http.get(url)
        .then((result: any) => result.data.map((countryTypeCode: any) => new TypeCodeValue(countryTypeCode)))
        .then((countryTypeCodes: TypeCodeValue[]) => this._countryTypeCodes = countryTypeCodes)
        .then(() => this._countryTypeCodes)
    }

    return this.$q.resolve(this._countryTypeCodes)

  }

  buildPayload(pid: string, allBeneficiaries: Beneficiaries[], orginalBeneficiaries: Beneficiaries[], effectiveDate: Date, sendCorrespondence: boolean, notificationInfo?: INotificationInfo): any {
    // const involvedParties: RolesSnapshot = this.resolvedInvolvedParties(allBeneficiaries, orginalBeneficiaries)
    const batchId: string = this.policyUtils.generateConfirmationNumber('BEN', pid, new Date())
    const effectiveDateString = new Date(effectiveDate.toLocaleDateString('en-US')).toJSON()
    const payload: any = allBeneficiaries.reduce((changeSet: any, benes: Beneficiaries, index: number): any => {
      const serializedBene: any = benes.serialize(orginalBeneficiaries[index].beneficiaries)

      changeSet.clientChanges.add = changeSet.clientChanges.add.concat(serializedBene.clientChanges.add)
      changeSet.clientChanges.update = changeSet.clientChanges.update.concat(serializedBene.clientChanges.update)

      changeSet.phoneChanges.add = changeSet.phoneChanges.add.concat(serializedBene.phoneChanges.add)
      changeSet.phoneChanges.update = changeSet.phoneChanges.update.concat(serializedBene.phoneChanges.update)
      changeSet.phoneChanges.delete = changeSet.phoneChanges.delete.concat(serializedBene.phoneChanges.delete)

      changeSet.roleChanges.add = changeSet.roleChanges.add.concat(serializedBene.roleChanges.add)
      changeSet.roleChanges.update = changeSet.roleChanges.update.concat(serializedBene.roleChanges.update)
      changeSet.roleChanges.delete = changeSet.roleChanges.delete.concat(serializedBene.roleChanges.delete)

      return changeSet
    }, { batchId, pid, effectiveDate: effectiveDateString, sendCorrespondence, notificationInfo, clientChanges: { add: [], update: [] }, phoneChanges: { add: [], update: [], delete: [] }, roleChanges: { add: [], update: [], delete: [] } })

    return payload
  }

  private benePostTransformHandler = (bene: Beneficiary, transformed) => {
    // handle updating roles property
    const csRole = bene.shareDistribution.toCSObject(bene)

    transformed = Object.assign(transformed, {...bene.name.toCSObject()})
    transformed.roles = [csRole]
    transformed.genderTypeCode = bene.gender?.transform()
    transformed.birthDate = bene.dob ? toDateString(bene.dob)/* .split('T')[0] */ : undefined

    if(bene.address?.isClean) {
      transformed.address = []
    } else {
      transformed.address = [transformed.address]
    }

    // @INFO: Hack to account unvailability id on net new benes
    if (bene.isNew && !transformed.id) transformed.id = 'NET_NEW_BENE_' + String(Math.ceil(Math.random() * 100))

    // Cleanup
    transformed.phones = transformed.phoneList
    // delete transformed.dob
    delete transformed.phoneList
    delete transformed.roleId

    return transformed
  }

  getAllPartiesInvolved(allBeneficiaries: Beneficiaries[], orginalBeneficiaries: Beneficiaries[]): RolesSnapshot {
    const beforeData = orginalBeneficiaries
      .reduce((flattend: Beneficiary[], benes: Beneficiaries):
        Beneficiary[] => flattend.concat(benes.beneficiaries),
        [] as Beneficiary[])
    const afterData = allBeneficiaries
      .reduce((flattend: Beneficiary[], benes: Beneficiaries):
        Beneficiary[] => flattend.concat(benes.beneficiaries),
        [] as Beneficiary[])

    const afterChanges: Beneficiary[] = afterData.filter((afterBene: Beneficiary) => {
      const beforeBene: Beneficiary | undefined = beforeData.find((bene) => bene.id === afterBene.id && bene.roleId === afterBene.roleId)
      const afterDiff = beforeBene ? deepCompareObjects(afterBene, beforeBene, ['group', 'sortOrder', 'partyType', 'amountBackup', 'blacklist', 'whitelist', 'serializableProperties'], true) : null
      const finalAnswer = (afterDiff && Object.keys(afterDiff).length) || !beforeBene

      // if (finalAnswer) console.log(beforeBene, afterDiff)

      return finalAnswer
    })
    const afterIds: string[] = afterChanges.map((afterBene: Beneficiary) => afterBene.id + afterBene.roleId)
    const beforeChanges: Beneficiary[] = beforeData
      .filter((beforeBene: Beneficiary) => afterIds.includes(beforeBene.id + beforeBene.roleId))

    return {
      beforeData: beforeChanges.map((bene: Beneficiary) => bene.transform(this.benePostTransformHandler.bind(this, bene))),
      afterData: afterChanges
        .filter((bene: Beneficiary) => !bene.toBeDeleted)
        .map((bene: Beneficiary) => bene.transform(this.benePostTransformHandler.bind(this, bene))),
    }
  }

  resolveDesignationFormUrl(policySummary, metaData): string {
    let beneDesignationUrl: string = '/crafter/assets/forms/'

    const foundMetaData = metaData.find((formMeta) => {
      const nyPolicy = policySummary.jurisdiction === 'New York'
      const foundCompany = formMeta.company.find((company) => policySummary.carrierCode === company)
      const foundProductType = formMeta.productType.find((productType) => policySummary.lineOfBusiness.toLowerCase() === productType)
      const foundJurisdiction = nyPolicy ? formMeta.issueState === 'New York' : formMeta.issueState === 'Not New York'

      return !!foundCompany && !!foundProductType && foundJurisdiction
    })

    if (foundMetaData) {
      beneDesignationUrl += foundMetaData.path
    }

    return beneDesignationUrl
  }

  async getDesignationFormUrl(policySummary: any): Promise<string> {
    return this.getBeneficiaryFormsMetadata().then((result) => this.resolveDesignationFormUrl(policySummary, result))
  }

  async validate(address: IAddressMininal, zipCheck: boolean = false): Promise<AddressValidationResult> {
    let url: string = zipCheck ? `${this.CONSTANTS.apiRoot}address/zip-lookup` : `${this.CONSTANTS.apiRoot}address/standardize`

    return this.$http.post(url, address).then((result) => result.data)
    .catch((error) => {
      switch(error.status) {
        case 400:
          return { hasError: true, message: 'We are unable to verify this address with the U.S. Postal Service. Are you sure you want to use this address?' }

        default:
          return { hasError: true, message: 'We\'re sorry, but there was a problem. Please contact Client Services to update your address. Client Services is available Monday through Friday, 8:30am to 6:00pm ET, at (800) 523-0650. Please call (855) 446-7393 if your policy was issued in New York.' }
      }
    })
  }

  async submitBeneficiaries(payload: any, isClientPortal: boolean = false): Promise<any> {
    const url = isClientPortal ? `${this.CONSTANTS.apiRoot}client/update-batch` : `${this.CONSTANTS.apiRoot}client/update`
    const payloadCopy = deepArrayOrObjectCopy(payload, ['ssn', 'birthDate', 'beforeSnapShot', 'afterSnapShot'])

    // console.log('submitBeneficiaries:', payload)

    this.loggingService.info(`Sending beneficiary changes to gateway: ${JSON.stringify(payloadCopy)}`, 'client-beneficiary-change')

    return this.$http.post(url, payload)
      .then((result) => new RoleChangeReponse(payload.batchId, result))
      .catch(err => new RoleChangeReponse(null, err))
  }

  restateBeneficiaries(effectiveDate: Date, pid: string): IPromise<any> {
    const url = `${this.CONSTANTS.apiRoot}client/restate-beneficiaries`
    const batchId: string = this.policyUtils.generateConfirmationNumber('BEN', pid, new Date())
    const effectiveDateString = new Date(effectiveDate.toLocaleDateString('en-US')).toJSON()
    const payload = {
      batchId,
      pid,
      effectiveDate: effectiveDateString,
      sendCorrespondence: true,
      clientChanges: {
        add: [],
        update: [],
      },
      phoneChanges: {
        add: [],
        update: [],
        delete: [],
      },
      roleChanges: {
        add: [],
        update: [],
        delete: [],
      },
    }

    return this.$http.post(url, payload)
      .then((result) => new RoleChangeReponse(payload.batchId, result))
      .catch(err => new RoleChangeReponse(null, err))
  }

  private beneficiaryFormsMetaData: any[]

  async getBeneficiaryFormsMetadata(): Promise<any> {
    const url = "/crafter/metadata/beneficiary-designation-forms"

    if (!this.beneficiaryFormsMetaData) {
      return this.$http.get(url)
        .then((rsp) => this.beneficiaryFormsMetaData = rsp.data as any[])
        .catch((err) => console.error(err))
    }

    return this.$q.resolve(this.beneficiaryFormsMetaData)
  }

}
