import * as angular from 'angular'
import {StateService} from '@uirouter/core'

type MaybeNumber = (number | undefined)

interface NoParamBoolean {
  (): boolean
}

enum PolicyNote {
    INTRODUCTION = 'INTRODUCTION',
    HOW_TO_CHANGE = 'HOW_TO_CHANGE',
    VIEW_ONLY = 'VIEW_ONLY',
    TRANSACTION_PENDING = 'TRANSACTION_PENDING',
    CONFIRMATION = 'CONFIRMATION',
    NO_NOTE = 'NO_NOTE',
    CALL_CUSTOMER_SERVICE = 'CALL_CUSTOMER_SERVICE',
  }

enum State {
    CHANGING = 'CHANGING',
    COMPLETE = 'COMPLETE',
    CONFIRMING = 'CONFIRMING',
    VIEWING = 'VIEWING',
    ERROR = 'ERROR',
  }

interface CurrentValueDisplay {
  (fund: Fund): string
}

interface FundParam {
  productCode: string
  percentage: number
}

interface FutureAllocations {
  [productCode: string]: MaybeNumber
}

interface SetAllocationsApiCall {
  (id: string, fundParamsArray: SetAllocationsPostData): Promise<any>
}

interface SetAllocationsPostData {
  fundParams: FundParam[]
}

interface NumberInputValueFromFund {
  (fund: SubAccountFromApi): number | undefined
}

interface Allocateable {
  currentValueDisplay: CurrentValueDisplay
  showCurrentValueColumn: NoParamBoolean
  showPercentageOfTotal: NoParamBoolean
  text: {
      actionName: string,
      confirmationMessage: string,
      currentValueDescriptor: string,
      failureMessage: string,
      h1: string,
      howToChange: string,
      introduction: string,
      transactionPending: string,
      viewOnly: string,
    }
  _eventName: string
  _inputFieldValue: NumberInputValueFromFund
  _setAllocationsApiCall: SetAllocationsApiCall
}

  // This is a JavaScript version of an abstract class.
  // Don't instantiate it directly.
  // Extend it, and fill in the protected fields.
abstract class AbstractAllocationsController {
  public adviserOwnerAgreementChecked: boolean
  public allowedValues
  public canAllocate: boolean
  public canRebalance: boolean
  public canView: boolean
  public confirmationMessage: string
  public confirmationNumber: string
  public error: string | undefined
  public futureAllocations: FutureAllocations
  public invalidProductCodes: {[productCode: string]: boolean} // Only boolean `true` values will appear in here for productCodes whose values are invalid.
  public opaqueOverlay: boolean
  public showChangeButton: boolean
  public showOverlay: boolean
  public state: State
  public subAccountTotal: number
  public _separatedFundCategories: SeparatedCategories | undefined

    // These protected/abstract fields must be overridden.
  protected _eventName: string
  protected _setAllocationsApiCall: SetAllocationsApiCall
  protected text: {
      confirmationMessage: string,
      failureMessage: string,
    }
  abstract _inputFieldValue (fund: SubAccountFromApi): number | undefined // Don't know why we can't use the NumberInputValueFromFund interface here.
  abstract _fundMode (): string
  abstract fundsToDisplay (): FundCategory[]
  abstract postInit (): void

  private _cachedConfirmingFundCategories?: FundCategory[]
  protected _cachedFundsWithFutureAllocations?: FundCategory[]
  protected _fundCategories: FundCategory[]
  private _originalFutureAllocations: FutureAllocations

  constructor (public $state: StateService, availableFunds, canAllocate, currentFunds, canRebalance, public summaryResult, public aarStatus,
      protected fundTransferService, private utils, private policyUtils, private $anchorScroll, private overlayUtils, private authService, protected $filter: angular.IFilterFunction) {
      const availableSubAccountArrayFromApi = this._subAccountArrayFromApiResult(availableFunds)
      const currentSubAccountArrayFromApi = this._subAccountArrayFromApiResult(currentFunds)
      const unableToAllocateReason = canAllocate.data && canAllocate.data.reason
      const unableToRebalanceReason = canRebalance.data && canRebalance.data.reason

      this.subAccountTotal = availableFunds.data && availableFunds.data.availableSubAccountTotal
      this.adviserOwnerAgreementChecked = false
      this.allowedValues = {ranges: [{maximum: 100, minimum: 0}], blank: true}
      this.state = State.VIEWING
      this.summaryResult = !summaryResult.error && summaryResult.policy

      if (availableSubAccountArrayFromApi && currentSubAccountArrayFromApi) {
        this.futureAllocations = this._futureAllocations(availableSubAccountArrayFromApi, currentSubAccountArrayFromApi)
        this.invalidProductCodes = {}
        // Make _originalFutureAllocations read-only so we don't somehow end up changing those values.
        Object.defineProperty(this, '_originalFutureAllocations', {
          configurable: false,
          enumerable: true,
          value: utils.hermeticCopy(this.futureAllocations),
          writable: false,
        })

        this._fundCategories = availableSubAccountArrayFromApi
          .reduce(this._addSubAccountFromApiToFunds.bind(this), [])
        this._fundCategories = this._fundCategories // XXX: TypeScript refuses to chain the map after the reduce.
          .map(category => {
            const updatedFunds = category.funds.map(availableFund => {
              const matchingCurrentFund = currentSubAccountArrayFromApi
                .filter(currentFund => currentFund.productCode === availableFund.productCode)[0]

              if (matchingCurrentFund) {
                return this._convertSubAccountFromApiToFund(matchingCurrentFund)
              }

              return availableFund
            })

            return {
              category: category.category,
              funds: updatedFunds,
            }
          })
      } else {
        this.futureAllocations = {}
        this._fundCategories = []
      }

      this.postInit()
      const totalFutureAllocations = this._totalFutureAllocations()

      this.canAllocate = !unableToAllocateReason && totalFutureAllocations === 100
      this.canRebalance = canRebalance.data && canRebalance.data.isTransactable
      this.showChangeButton = this._showChangeButton(unableToAllocateReason)
      this.canView = this._canView(unableToAllocateReason, totalFutureAllocations, unableToRebalanceReason)
      this.error = this._errorCode(unableToAllocateReason, currentFunds, availableFunds, totalFutureAllocations)

      if (this.error) {
        window.dataLayer.push({
          event: this._eventName,
          errorCode: this.error,
          policyId: this.$state.params.id,
        })
      }
    }

  areFutureAllocationsChanged (): boolean {
      return Object.keys(this.futureAllocations)
        .map(productCode => this.hasFutureAllocationPercentageChanged(productCode))
        .filter(angular.identity)
        .length !== 0
    }

  areFutureAllocationsValid (): boolean {
      return this._isEachAllocationValid() && this._validAllocations().filter(Number.isInteger).reduce(this.utils.sum, 0) === 100
    }

  change () {
      return this.overlayUtils.transition(this, this._change)
    }

  goToPolicySummary () {
      let route = this.authService.isARealClient() ? 'myPolicy' : 'policy'

      return this.utils.goToRouteAndReload(route)
    }

  fundAllocationInputId (productCode: string): string {
      return `fundAllocationInput${productCode}`
    }

  handleAllocationChange (productCode: string): void {
      if (this.isFundAllocationValid(productCode)) {
        delete this.invalidProductCodes[productCode]
      }
    }

  hasFutureAllocationInputChanged (productCode: string): boolean {
      return this.futureAllocations[productCode] !== this._originalFutureAllocations[productCode]
    }

  hasFutureAllocationPercentageChanged (productCode: string): boolean {
      return (this.futureAllocations[productCode] || 0) !== (this._originalFutureAllocations[productCode] || 0)
    }

  isFundAllocationValid (productCode: string): boolean {
      const value: MaybeNumber = this.futureAllocations[productCode]

      return typeof value === 'undefined' || Number.isInteger(value) && this._isBetweenZeroAndOneHundredOrBlank(value)
    }

  policyNote (): PolicyNote {
      if (this._totalFutureAllocations() !== 100 && this._fundMode() === 'allocations') {
        return PolicyNote.CALL_CUSTOMER_SERVICE
      }

      if (this.canView && this.error) {
        return PolicyNote.VIEW_ONLY
      }

      if (!this.canView) {
        return PolicyNote.TRANSACTION_PENDING
      }

      if (this.state === State.CHANGING) {
        return PolicyNote.HOW_TO_CHANGE
      }

      if ((this.canAllocate || this.canRebalance) && this.state === State.VIEWING) {
        return PolicyNote.INTRODUCTION
      }

      if (this.confirmationMessage) {
        return PolicyNote.CONFIRMATION
      }

      return PolicyNote.NO_NOTE
    }

  save () {
      return this.overlayUtils.transition(this, this._save)
    }

  saveButtonEnabled (): boolean {
      if (this.state === State.CONFIRMING && !this.adviserOwnerAgreementChecked) {
        return false
      }

      return this.areFutureAllocationsValid() && this.areFutureAllocationsChanged() && !this._requireAdviserOwnerAgreement()
    }

  selectTarget ($event): void {
      $event.target.select()
    }

  totalFutureAllocations () {
      const sum: number = this._allocationValues()
        .filter(this._isActualNumber)
        .reduce(this.utils.sum, 0) as number

      // Remove any weird JS rounding stuff that might happen when the user enters a fraction
      return parseFloat(sum.toFixed(1))
    }

  updateFieldsAndValidity ($event): void {
      const inputElement = $event.target
      const productCode: string = inputElement.getAttribute('data-product-code')

      this._normalizeField(productCode, inputElement)

      if (this.isFundAllocationValid(productCode)) {
        delete this.invalidProductCodes[productCode]
      } else {
        this.invalidProductCodes[productCode] = true
      }
    }

  _addSubAccountFromApiToFunds (category: FundCategory[], subAccount: SubAccountFromApi): FundCategory[] {
      const lastCategory: FundCategory = category[category.length - 1]
      const fund: Fund = this._convertSubAccountFromApiToFund(subAccount)

      if (lastCategory && subAccount?.category?.value === lastCategory.category) {
        lastCategory.funds.push(fund)
      } else {
        category.push({
          category: subAccount?.category?.value ?? subAccount.productFullName,
          funds: [fund],
        })
      }

      return category
    }

  _allocationValues (): MaybeNumber[] {
      if (this.futureAllocations) {
        return Object.keys(this.futureAllocations)
          .map(this.utils.getFrom(this.futureAllocations))
      }

      return []
    }

  _canView (unableToAllocateReason, totalFutureAllocations, unableToRebalanceReason): boolean {
      if (this._fundMode() === 'allocations') {
        if (totalFutureAllocations !== 100) {
          return false
        }

        if (Object.keys(this.futureAllocations).length === 0) {
          return false
        }

        if (unableToAllocateReason) {
          return unableToAllocateReason.tc !== '1'
        }
      }

      if (this._fundMode() === 'rebalance') {
        if (unableToRebalanceReason) {
          return false
        }
      }

      return true
    }

  _change (): void {
      if (this.state === State.VIEWING) {
        this.state = State.CHANGING
      } else {
        this.state = State.VIEWING
      }
    }

    // Fund categories we show when the user is confirming future allocation changes:
    // any allocations that have been changed,
    // and all fund categories that have non-zero future allocations.
  _confirmingFundCategories (): FundCategory[] {
      if (this._cachedConfirmingFundCategories) {
        return this._cachedConfirmingFundCategories
      }

      const changingProductCodes = Object.keys(this._originalFutureAllocations)
        .filter(fund => this.hasFutureAllocationPercentageChanged(fund))
      const nonZeroAllocationProductCodes = Object.keys(this.futureAllocations)
        .filter(this.utils.getFrom(this.futureAllocations))
      const fundProductCodesToConfirm = changingProductCodes.concat(nonZeroAllocationProductCodes)
      let isFundToConfirm = fund => fundProductCodesToConfirm.indexOf(fund.productCode) !== -1

      if (this._fundMode() === 'rebalance') {
        isFundToConfirm = fund => fundProductCodesToConfirm.indexOf(fund.productCode) !== -1 || fund.totValue
      }

      // Run through each category's funds,
      // and filter out those that aren't being changed.`
      // Then filter out each category with a blank funds array.
      return this._cachedConfirmingFundCategories = this._fundCategories
        .map(fundCategory => ({
          category: fundCategory.category,
          funds: fundCategory.funds.filter(isFundToConfirm),
        }))
        .filter(fundCategory => fundCategory.funds.length !== 0)
    }

  _confirmationNumber = this.policyUtils.generateConfirmationNumber.bind(this, 'FA')

  _convertSubAccountFromApiToFund (subAccount: SubAccountFromApi): Fund {
      return {
        currentAllocationPercent: subAccount.subsequentPremiumAllocationTransferPct || 0,
        productCode: subAccount.productCode,
        productFullName: subAccount.productFullName,
        totValue: subAccount.totValue || 0,
        canTransact: subAccount.canTransact,
        // transactable: subAccount.transactable,
        // nonTransactable: subAccount.nonTransactable,
      }
    }

  _errorCode (unableToAllocateReason, currentFunds, availableFunds, totalFutureAllocations): string | undefined {
      if (this._fundMode() === 'allocations') {
        return (unableToAllocateReason && unableToAllocateReason.tc) ||
        this._stringifyNon2xxStatus(currentFunds, '900C') ||
        this._stringifyNon2xxStatus(availableFunds, '900A') ||
        (totalFutureAllocations !== 100 && '900T')
      }
    }

  _futureAllocations (availableSubAccountArrayFromApi: SubAccountFromApi[], currentSubAccountArrayFromApi: SubAccountFromApi[]): FutureAllocations {
      const reducer = (accumulator: FutureAllocations, fund: SubAccountFromApi): FutureAllocations => {
        // We intentionally assign undefined to zero values so their respective inputs are blank.
        accumulator[fund.productCode] = this._inputFieldValue(fund) || undefined

        return accumulator
      }

      return currentSubAccountArrayFromApi.reduce(reducer, availableSubAccountArrayFromApi.reduce(reducer, {}))
    }

  _fundsWithFutureAllocations (): FundCategory[] {
      if (this._cachedFundsWithFutureAllocations) {
        return this._cachedFundsWithFutureAllocations
      }

      return this._cachedFundsWithFutureAllocations = this._fundCategories
        .map(fundCategory => ({
          category: fundCategory.category,
          funds: fundCategory.funds.filter(fund => Boolean(this.futureAllocations[fund.productCode])),
        }))
        .filter(fundCategory => fundCategory.funds.length !== 0)
    }

  _integerOrBlankAllocations (): MaybeNumber[] {
      return this._allocationValues()
        .filter(value => typeof value === 'undefined' || Number.isInteger(value))
    }

  _isActualNumber (value: any): boolean {
      return typeof value === 'number' && !isNaN(value)
    }

  _isEachAllocationValid (): boolean {
      if (!this.futureAllocations) {
        return false
      }

      const numberOfAllocations = Object.keys(this.futureAllocations).length

      return numberOfAllocations !== 0 && this._validAllocations().length === numberOfAllocations
    }

  _isBetweenZeroAndOneHundredOrBlank (x: MaybeNumber): boolean {
      return typeof x === 'undefined' || (x >= 0 && x <= 100)
    }

  _normalizeField (productCode: string, inputElement): void {
      const trimmedValue = inputElement.value.trim()

      if (typeof this.futureAllocations[productCode] !== 'undefined') {
        if (trimmedValue === '') {
          const wasOriginal = this._originalFutureAllocations[productCode]
          // Mark a fund which origanlly was non-zero as zero.
          // Blank out a fund that was originally blank.

          this.futureAllocations[productCode] =
            wasOriginal
              ? 0
              : undefined
        } else {
          const floatValue = parseFloat(trimmedValue)
          const hasLeadingZeros = trimmedValue !== '0' && trimmedValue[0] === '0'
          const hasUnnecessaryZeroDecimals = String(floatValue) !== trimmedValue

          if (hasLeadingZeros || hasUnnecessaryZeroDecimals) {
            // Convert 033 into 33.
            // Convert 3.0 into 3.
            inputElement.value = this.futureAllocations[productCode] = floatValue
          }
        }
      }
    }

  _save (): void | Promise < void > {
      if (this.state === State.CONFIRMING) {
        window.dataLayer.push({
          event: this._eventName,
          action: 'confirm',
          policyId: this.$state.params.id,
        })

        if (this.areFutureAllocationsChanged()) {
          let postData = this._setAllocationsPostData()

          if (this._fundMode() === 'rebalance') {
            postData = this._setRebalancePostData()
          }

          return this._setAllocationsApiCall(this.$state.params.id, postData)
            .then(() => {
              this.confirmationNumber = this._confirmationNumber(this.$state.params.id, new Date())
              this.confirmationMessage = this.text.confirmationMessage
              window.dataLayer.push({
                event: this._eventName,
                result: 'SUCCESS',
                policyId: this.$state.params.id,
              })
            })
            .then(() => {
              this.state = State.COMPLETE
              this.$anchorScroll()
            })
            .catch(error => {
              this.state = State.ERROR
              window.dataLayer.push({
                event: this._eventName,
                errorCode: error.status,
                result: 'FAILURE',
                policyId: this.$state.params.id,
              })
              this.error = error && error.data && error.data.code || '99'
            })
        }
      } else {
        window.dataLayer.push({
          event: this._eventName,
          action: 'save',
          policyId: this.$state.params.id,
        })
        this.state = State.CONFIRMING
        // Invalidate the caches so they will be re-calculated.
        delete this._cachedConfirmingFundCategories
        delete this._cachedFundsWithFutureAllocations
      }
    }

  _setAllocationsPostData (): SetAllocationsPostData {
      const fundParams = Object.keys(this.futureAllocations)
        .filter(this.utils.getFrom(this.futureAllocations)) // Will filter out zero-values/undefineds/nulls.
        .map(productCode => ({
          productCode: productCode,
          percentage: this.futureAllocations[productCode],
        })) as FundParam[]

      return {
        fundParams: fundParams,
      }
    }

  _setRebalancePostData (): SetAllocationsPostData {
      const fundParams = Object.keys(this.futureAllocations)
        .filter(this.utils.getFrom(this.futureAllocations)) // Will filter out zero-values/undefineds/nulls.
        .map(productCode => ({
          productCode: productCode,
          percentage: this.futureAllocations[productCode],
          usage: {
            value: 'Destination',
            tc: '2',
          },
        })) as FundParam[]

      return {
        fundParams: fundParams,
      }
    }

  _showChangeButton (unableToAllocateReason): boolean {
      return Boolean(!unableToAllocateReason || !['2', '4', '5', '11'].includes(unableToAllocateReason.tc))
    }

  _stringifyNon2xxStatus (responseObject, fallbackStatus) {
      if (responseObject) {
        if (responseObject.status) {
          const statusString = String(responseObject.status)

          if (statusString[0] !== '2') {
            return statusString
          }
        }
      } else {
        return fallbackStatus
      }
    }

  _subAccountArrayFromApiResult (apiResult: any): SubAccountFromApi[] | undefined {
      return apiResult && apiResult.data && apiResult.data.subAccount
    }

  _totalFutureAllocations (): number {
      return this._fundCategories.map(
        fundCategory => fundCategory.funds
          .map(fund => fund.currentAllocationPercent)
          .reduce(this.utils.sum, 0),
        )
        .reduce(this.utils.sum, 0)
    }

  _validAllocations (): MaybeNumber[] {
      return this._integerOrBlankAllocations()
        .filter(this._isBetweenZeroAndOneHundredOrBlank)
    }

  _requireAdviserOwnerAgreement (): boolean {
      return this.state === 'CONFIRMING' && !this.authService.isARealClient()
    }

  _currentPercentageOfTotal (fundValue, subAccountsTotal): number {
      return this.utils.percentageOfTwoNumbers(fundValue, subAccountsTotal)
    }

  _capitalizeString (word): string {
      return this.utils.capitalizeString(word)
    }

  _actionableFunds (funds: Fund[]): Fund[] {
      return funds.filter(fund => Boolean((fund.totValue || fund.currentAllocationPercent) && fund.canTransact))
    }

  _valuableNonActionableFunds (funds: Fund[]): Fund[] {
      return funds.filter(fund => Boolean((fund.totValue || fund.currentAllocationPercent) && !fund.canTransact))
    }

  _categoryHasTransactableFunds (funds: Fund[]): boolean {
      return funds.some(fund => Boolean(fund.canTransact))
    }
}

export class FundRebalanceController extends AbstractAllocationsController implements Allocateable {
  postInit () {
     this._separatedFundCategories = this._buildSeparatedCategories()
   }

  _buildSeparatedCategories (): SeparatedCategories {
      // Funds the user is currently interested in:
      // either that s/he currently has invested in,
      // or has allocated future premiums towards.

     const result: SeparatedCategories = {
        actionableCategories: [],
        nonActionableCategories: [],
      }

     this._fundCategories.forEach(fundCategory => {
        const transactable = this._actionableFunds(fundCategory.funds)
        const nonTransactable = this._valuableNonActionableFunds(fundCategory.funds)

        if (transactable.length > 0) {
          result.actionableCategories.push({
            category: fundCategory.category,
            funds: transactable,
          })
        }

        if (nonTransactable.length > 0) {
          result.nonActionableCategories.push({
            category: fundCategory.category,
            funds: nonTransactable,
          })
        }
      })

     return result
   }

  fundsToDisplay (): FundCategory[] {
     if (this.state === State.CHANGING) {
        return this._fundCategories // See them all.
      }

     if (this.state === State.CONFIRMING) {
        return this._confirmingFundCategories()
      }

     if (this.state === State.COMPLETE) {
        return this._fundsWithRebalance()
      }

     if (this._separatedFundCategories) {
        // We cache this result because the user's invested funds shouldn't change in the lifetime of this controller.
        return this._separatedFundCategories.actionableCategories
      }

     return []
   }

  _fundsWithRebalance (): FundCategory[] {
     if (this._cachedFundsWithFutureAllocations) {
        return this._cachedFundsWithFutureAllocations
      }

     return this._cachedFundsWithFutureAllocations = this._fundCategories
        .map(fundCategory => ({
          category: fundCategory.category,
          funds: fundCategory.funds.filter(fund => Boolean(this.futureAllocations[fund.productCode]) || fund.totValue),
        }))
        .filter(fundCategory => fundCategory.funds.length !== 0)
   }

  currentValueDisplay (fund) {
     return this.$filter('currency')(fund.totValue || 0)
   }
  showCurrentValueColumn () {
     return false
   }
  showPercentageOfTotal () {
     return this.state === 'CHANGING' ||
        this.state === 'VIEWING' ||
        this.state === 'CONFIRMING' ||
        this.state === 'COMPLETE'
   }

  policyType () {
     if (this.summaryResult) {
        return this.summaryResult.lineOfBusiness === 'Annuity' ? 'contract' : 'policy'
      }
   }

  resetValues (): number {
     return 0
   }

  _fundMode () {
     return 'rebalance'
   }

  text = {
     actionName: 'fund rebalance',
     confirmationMessage: 'Your rebalance request has been initiated. Rebalance requests accepted before the close of the stock market (typically 4:00pm ET) will be valued using the closing unit values for that day. Requests accepted after the close of the market will use unit values at the close of the next trading day. Percentage amounts displayed are estimates. Due to market fluctuations, the unit values at time of rebalance may differ from current unit values.',
     currentValueDescriptor: 'Value',
     failureMessage: 'Sorry, we are unable to perform a fund rebalance. Please try again in a few hours, and contact support if you continue to receive this message.',
     h1: 'Fund Rebalance',
     howToChange: `You can change how you would like to allocate your ${this.policyType()} value among funds using the form below.`,
     introduction: `You can change how the value of your ${this.policyType()} is allocated among eligible funds.`,
     transactionPending: `Sorry, you cannot rebalance your funds because a request for fund rebalance is pending.`,
     viewOnly: `Please see below for a listing of your current asset distribution among investment choices.`,
   }
  _eventName = 'FUND REBALANCE'
  _inputFieldValue () {
     return 0
   }
  _setAllocationsApiCall = (policyId, postData) => {
      // This needs to wrap the call to fundTransferService rather than set it directly,
      // based on timing between the class definition and the constructor.
     return this.fundTransferService.rebalanceFunds(policyId, postData)
   }
}

export class FutureAllocationsController extends AbstractAllocationsController implements Allocateable {
  protected _interestingFundCategories: FundCategory[] | undefined

  postInit () {
      this._interestingFundCategories = this._interestingFunds()
    }

  _interestingFunds (): FundCategory[] {
      // Funds the user is currently interested in:
      // either that s/he currently has invested in,
      // or has allocated future premiums towards.
      const isInteresting = fund => Boolean(fund.totValue || fund.currentAllocationPercent)

      return this._fundCategories
        .map(fundCategory => ({
          category: fundCategory.category,
          funds: fundCategory.funds.filter(isInteresting),
        }))
        .filter(fundCategory => fundCategory.funds.length !== 0)
    }

  fundsToDisplay (): FundCategory[] {
      if (this.state === State.CHANGING) {
        return this._fundCategories // See them all.
      }

      if (this.state === State.CONFIRMING) {
        return this._confirmingFundCategories()
      }

      if (this.state === State.COMPLETE) {
        return this._fundsWithFutureAllocations()
      }

      if (this._interestingFundCategories) {
        // We cache this result because the user's invested funds shouldn't change in the lifetime of this controller.
        return this._interestingFundCategories
      }

      return []
    }

  currentValueDisplay (fund) {
      return this.$filter('percent')(fund.currentAllocationPercent || 0)
    }
  showCurrentValueColumn () {
      return this.state !== 'COMPLETE'
    }
  showPercentageOfTotal () {
      return false
    }

  _fundMode () {
      return 'allocations'
    }
  text = {
      actionName: 'future allocation',
      confirmationMessage: 'Your future allocations have been updated. Additional contributions will be allocated among investments as follows:',
      currentValueDescriptor: 'Allocation',
      failureMessage: 'Sorry, we are unable to update your future allocations. Please try again in a few hours, and contact support if you continue to receive this message.',
      h1: 'Future Allocation',
      howToChange: `You can change how you would like future contributions allocated among investment choices using the form below. Please specify percentages in whole numbers and make sure that the total of all allocations is 100%.`,
      introduction: `Please see below for a listing of how future contributions will be allocated among investment choices. You can change these future allocations by clicking the “Change” button above.`,
      transactionPending: `Sorry, you cannot change your future allocations. A request to change future allocations is pending.`,
      viewOnly: `Please see below for a listing of how future contributions will be allocated among investment choices.`,
    }
  _eventName = 'FUTURE ALLOCATION'
  _inputFieldValue (fund) {
      return fund.subsequentPremiumAllocationTransferPct
    }
  _setAllocationsApiCall = (policyId, postData) => {
      return this.fundTransferService.setFutureAllocations(policyId, postData)
    }
}

let dependencies = ['$state', 'availableFunds', 'canAllocate', 'currentFunds', 'canRebalance', 'summaryResult', 'aarStatus', 'fundTransferService', 'utils', 'policyUtils', '$anchorScroll', 'overlayUtils', 'authService', '$filter']

FutureAllocationsController.$inject = dependencies
FundRebalanceController.$inject = dependencies
