import * as angular from 'angular'

interface NumFoundResult {
  numFound: number
}

export class SearchService {

  constructor (private $http: angular.IHttpService, private loggingService, private utils, private dateUtils,
               private clientUtils, private policyUtils, private CONSTANTS, private partyService) {
  }

  search (criteria: string, pageNumber?: number) {
    let resultContainer = this.getResultTemplate()
    let url = this.CONSTANTS.isDataServiceActive ? this.CONSTANTS.searchURL.replace('{0}', criteria).replace('{1}', pageNumber) : 'app/mock-api/search-results.json'

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())

    const promise = this.$http.get(url).then(this.processResults.bind(this),
         (httpData) => {
           this.utils.fillAndLogError(httpData, resultContainer)
           return resultContainer
         })

    return promise
  }

  searchByTaxId (taxId, pageNumber) {
    let url = `${this.CONSTANTS.apiRoot}search/advanced?page=${pageNumber}`

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())

    const searchPayload = {
      ssn: taxId,
    }

    const promise = this.$http.post(url, searchPayload).then(this.processResults.bind(this),
         (httpData) => {
           let resultContainer = this.getResultTemplate()
           this.utils.fillAndLogError(httpData, resultContainer)
           return resultContainer
         })

    return promise
  }

  getUnsubmittedApplicationCount () {
    const result = {
      error: '',
      status: '',
      statusText: '',
      unsubmittedApplicationCount: {},
    }

    let url = this.CONSTANTS.unsubmittedApplicationsTotalCountURL

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())

    let promise = this.$http.get<NumFoundResult>(url).then((httpData) => {
          if (httpData.data) {
            result.unsubmittedApplicationCount = httpData.data.numFound
            return result
          }
          result.error = 'Undefined response'
          return result
        }).catch((httpData) => {
          this.utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

  getResultTemplate = () => ({
    error: '',
    status: '',
    resultType: '',
    resultList: Array(),
  })

  private processResults (httpData) {
    let resultContainer = this.getResultTemplate()

    if (httpData.data) {
      if (httpData.data.partyResults) {
        resultContainer.resultType = this.CONSTANTS.searchTypeResult.client
        let list = httpData.data.partyResults

        list.forEach((client) => {
          try {
            let item: any = {}

            item.clientID = client.id
            // "Person or "Organization"
            item.partyType = client.partyType
            item.isAnOrganization = client.partyType !== this.CONSTANTS.personPartyType

            item.firstName = client.middleName ? client.firstName + ' ' + client.middleName : client.firstName
            item.middleName = client.middleName
            item.lastName = client.lastName
            item.fullName = this.clientUtils.partyName(client)
            item.birthDate = this.dateUtils.parseDate(client.birthDate)
            item.calculatedAge = item.birthDate ? this.dateUtils.getYearsFrom(item.birthDate) : client.age
            item.age = client.age
            item.gender = client.gender
            item.phone = ''
            item.phoneType = ''
            item.address = ''

            if (client.address && client.address.length > 0) {
              item.address = this.clientUtils.getAddress(client.address[0])
              item.htmlAddress = item.address
            }

            if (client.phones?.length > 0) {
              item.phone = client.phones[0].number
              item.phoneType = client.phones[0].phoneType
            }

            item.uid = this.clientUtils.getClientHash(item)

            resultContainer.resultList.push(item)
          } catch (e) {
            this.loggingService.log('Error processing a Client received in Search: ' + e, 'error')
          }
        })
      } else if (httpData.data.policyResults) {
        resultContainer.resultType = this.CONSTANTS.searchTypeResult.policy

        // As per the discussion on basecamp: https://basecamp.com/2062173/projects/8291188/messages/46291613#comment_316240940
        // I'm setting the first policy to inforce by default
        if (httpData.data.policyResults.length > 0) {
          let policy = httpData.data.policyResults[0]

          policy.type = this.policyUtils.getPolicySourceFromStatus(policy.holdingStatus, policy.source)
          resultContainer.resultList.push(policy)
        }
      }

      return resultContainer
    }

  }

}
