 import {StateService} from '@uirouter/core'

enum State {
    CHANGING = 'CHANGING',
    COMPLETE = 'COMPLETE',
    CONFIRMING = 'CONFIRMING',
    DISTRIBUTION = 'DISTRIBUTION',
  }

interface DestinationFund extends Fund {
  estimatedDollarAmount: number
  transferPercentage?: number
}

interface DestinationFundCategory {
  category: string
  funds: DestinationFund[]
}

interface AnalyticsEvent {
  event: string,
  action: string,
  policyId: string,
  errorCode?: string,
}

export class FundsTransferController {

  public adviserOwnerAgreementChecked: boolean
  public hasFundWithPendingTransfer: boolean
  public destinationFundsArray: DestinationFund[]
  public currentFundsArray: Array<any>
  public sourceFund
  public resultValue: number
  public allowedValues
  public sourceTransferPercentage
  private destinationFundsArrayOriginal
  public confirmationNumber: string
  public confirmationMessage: string
  public error: string
  public state: State
  public fundCategories: DestinationFundCategory[] | undefined = []
  public showOverlay: boolean
  public opaqueOverlay: boolean
  public focusPercentOut: boolean

  constructor (public $state: StateService, destinationFunds, currentFunds, private fundTransferService, private policyUtils, private $anchorScroll, private overlayUtils, private utils, private authService) {
      this.adviserOwnerAgreementChecked = false
      this.state = State.CHANGING
      this.allowedValues = {ranges: [{maximum: 100, minimum: 1}]}
      this.destinationFundsArray = destinationFunds.data && destinationFunds.data.subAccount
      this.destinationFundsArrayOriginal = this.destinationFundsArray
      this.currentFundsArray = currentFunds.data && currentFunds.data.subAccount || []
      this.currentFundsArray = this.currentFundsArray.filter(account => account.estValue && account.estValue > 0 || !account.estValue && account.totValue > 0)  // filter out funds that have 0 value
      this.resultValue = 0
      this.sourceTransferPercentage = 0
      this.fundCategories = this._extractFundCategories(this.destinationFundsArray) // maybe do this after source pick
      this.hasFundWithPendingTransfer = this._hasFundWithPendingTransfer()
      this.focusPercentOut = false

      // Start with the overlay up.
      this.opaqueOverlay = true
      this.showOverlay = true

      // Fade out the overlay
      overlayUtils.fadeOut(this, 333)
      this._emitEvent('start') // analytics start event
    }

  destinationFundPercentageChanged (fund: DestinationFund): void {
      if (typeof fund.transferPercentage !== 'number') {
        fund.estimatedDollarAmount = 0
      }
      this._reestimateDollarAmounts()
    }

  requireAdviserOwnerAgreement (): boolean {
      return this.state === 'CONFIRMING' && !this.authService.isARealClient()
    }

  cancelRoute () {
      if (this.$state.current.name === 'myPolicy.transfer') {
        this.$state.go('myPolicy')
      } else {
        this.$state.go('policy')
      }
    }

  _reestimateDollarAmounts (): void {
      const allocatedDestinations: DestinationFund[] = this.destinationFundsArray
        .filter(currentFund => currentFund.transferPercentage)
      const allocatedDestinationsCount = allocatedDestinations.length
      let runningTotal = 0

      allocatedDestinations.forEach((fund, index) => {
        // Recalculate and set each of these guys.
        // Treat the last one differently if we're fully allocated.
        // In that case instead of doing multiplication, do subtraction.
        const dollarAmount = this._calculateDollarAmount(fund.transferPercentage || 0)

        if (index === allocatedDestinationsCount - 1 && this.isFullyAllocated()) {
          fund.estimatedDollarAmount = this.utils.roundDecimal(this.getSourceTotal() - runningTotal, 2)
        } else {
          fund.estimatedDollarAmount = dollarAmount
          runningTotal += dollarAmount

        }
      })
    }

  _calculateDollarAmount (transferPercentage: number) {
      return this.utils.roundDecimal(transferPercentage * .01 * this.getSourceTotal(), 2)
    }

  groupByFundCategory (fund) { // grouping function called by ui-select
      return fund.category.value
    }

  _extractFundCategories (sourceArray: any[]) {
      let categoryNames: string[] = sourceArray.map(fund => {
        return fund.category.value
      })
      let uniqueCategoryNames = categoryNames.filter((value, i, a) => a.indexOf(value) === i)

      let categories: DestinationFundCategory[] = uniqueCategoryNames.map(categoryName => {
        let category: DestinationFundCategory = {category: categoryName, funds: []}
        category.funds = sourceArray
          .filter(fund => fund.category.value === categoryName)
          .map(fund => {
            fund.estimatedDollarAmount = 0

            return fund
          })
        return category
      })

      return categories
    }

  _limitDestinationCategories () {
      if (this.fundCategories) {
        this.fundCategories.forEach(category => {
          category.funds = category.funds.filter(fund => fund.transferPercentage && fund.transferPercentage > 0) // limit funds to those are populated with a %
        })
        this.fundCategories = this.fundCategories.filter(category => category.funds.length > 0)  // limit categories to those that contain populated funds
      }
    }

  _emitEvent (action: string, errorCode?: string) {
      let eventObject: AnalyticsEvent = {
        event: 'FUND TRANSFER',
        action: action,
        policyId: this.$state.params.id,
      }

      if (errorCode) {
        eventObject.errorCode = errorCode
      }
      window.dataLayer.push(eventObject)
    }

  getFundValue (fund: Fund): number {
      if (fund) {
        if (typeof fund.estValue === 'number') {
          return fund.estValue
        }

        return fund.totValue
      }

      return 0
    }

  getSourceTotal (): number {
      if (this.sourceFund) {
        return this.utils.roundDecimal(this.sourceTransferPercentage * .01 * this.getFundValue(this.sourceFund))
      }

      return 0
    }

  showSourcePendingDisclaimer (): boolean {
      return (this.hasFundWithPendingTransfer && this.state === State.CHANGING) || (this.isTransferPending(this.sourceFund))
    }

  showDestinationPendingDisclaimer (): boolean {
      return (this.hasFundWithPendingTransfer && this.state === State.DISTRIBUTION) || (this._isTransferPendingForSelectedDestinations())
    }

  _isTransferPendingForSelectedDestinations (): boolean {
      let destinations = this.getDestinationFundsWithValue()
      let pendingDestinations = destinations.filter(destination => this.isTransferPending(destination))

      return (pendingDestinations && pendingDestinations.length > 0)
    }

  sourceHasPendingTransfer (): boolean {
      return (this.isTransferPending(this.sourceFund))
    }

  filterDestinationFunds () {
      let index = this.destinationFundsArrayOriginal.findIndex(fund => fund.productCode === this.sourceFund.productCode)

      this.destinationFundsArray = this.destinationFundsArrayOriginal.slice(0)
      this.destinationFundsArray.splice(index, 1)
      this.fundCategories = this._extractFundCategories(this.destinationFundsArray)
      this.focusPercentOut = true
    }

  isValidTotalPercentage () {
      return this.sourceTransferPercentage <= 100 && this.sourceTransferPercentage > 0 && this.getDestinationsTransferPercentage() === 100
    }

  isFullyAllocated () {
      return this.getDestinationsTransferPercentage() === 100
    }

  isTransferPending (fund: Fund): boolean {
      if (fund) {
        if (typeof fund.estValue === 'number') {
          return true
        }
      }
      return false
    }

  _hasFundWithPendingTransfer (): boolean {
      let fundWithPendingTransfer = false

      if (this.fundCategories) {
        this.fundCategories.forEach(category => {
          if (!fundWithPendingTransfer) {
            let foundTransfers = category.funds.filter(fund => this.isTransferPending(fund))

            if (foundTransfers && foundTransfers.length > 0) {
              fundWithPendingTransfer = true
            }
          }
        })
      }
      return fundWithPendingTransfer
    }

  getDestinationsTransferPercentage (): number {
      let totalTransferPct = 0

      if (this.fundCategories) {
        this.fundCategories.forEach(category => {
          category.funds.forEach(fund => {
            totalTransferPct += fund.transferPercentage || 0
          })
        })
      }
      return totalTransferPct
    }

  getDestinationFundsWithValue (): DestinationFund[] {
      let destinationFunds: DestinationFund[] = []

      if (this.fundCategories) {
        this.fundCategories.forEach(category => {
          destinationFunds = destinationFunds.concat(category.funds.filter(fund => fund.transferPercentage && fund.transferPercentage > 0))
        })
      }
      return destinationFunds
    }

  isValidPercentOut (): boolean {
      return this.sourceTransferPercentage > 0
    }

  editFunds (): void {
      this.state = State.DISTRIBUTION
    }

  _getDestinationParams (): FundParam[] {
      let destinationFunds: DestinationFund[] = this.getDestinationFundsWithValue()

      return destinationFunds.map(fund => {
        let param: FundParam = {
          usage: {value: 'Destination', tc: '2'},
          productCode: fund.productCode,
          percentage: fund.transferPercentage || 0, // @TODO calculate dest PCT or maybe not
        }

        return param
      })
    }

  isTransferReady () {
      return this.isValidTotalPercentage() && this.sourceFund !== undefined && this.isValidTotalPercentage()
    }

  _confirm () {
      this.state = State.CONFIRMING
      this._limitDestinationCategories()
    }

  confirm () {
      this._emitEvent('transfer-review')
      return this.overlayUtils.transition(this, this._confirm)
    }

  previewConfirm () {
      // @TODO -- remove this method later, this is just to set state like we have sent a transfer
      this.confirmationNumber = this.policyUtils.generateConfirmationNumber('FT', this.$state.params.id, new Date())
      this.confirmationMessage = 'Your transfer has been initiated. Transfer requests accepted before the close of the stock market (typically 4:00pm ET) will be valued using the closing unit values for that day. Requests accepted after the close of the market will use unit values at the close of the next trading day. Dollar amounts displayed are estimates. Due to market fluctuations, the dollar amount at time of transfer may differ from the estimated dollar amounts.'
      this.sourceFund = this.currentFundsArray[0]
      this.state = State.COMPLETE
    }

  selectTarget ($event): void {
      $event.target.select()
    }

  goToPolicySummary () {
      let route = this.authService.isARealClient() ? 'myPolicy' : 'policy'

      return this.utils.goToRouteAndReload(route)
    }

  _transfer () {
      let source: FundParam = {
        usage: {value: 'Source', tc: '1'},
        percentage: this.sourceTransferPercentage,
        productCode: this.sourceFund.productCode,
      }

      let destinations: FundParam[] = this._getDestinationParams()
      let transferPayload: FundParameters = {fundParams: [source].concat(destinations)}

      return this.fundTransferService.transferFunds(this.$state.params.id, transferPayload)
          .then(() => {
            this.confirmationNumber = this.policyUtils.generateConfirmationNumber('FT', this.$state.params.id, new Date())
            this.confirmationMessage = `Your transfer has been initiated. Transfer requests accepted before the close of the stock market (typically 4:00pm ET) will be valued using the closing unit values for that day. Requests accepted after the close of the market will use unit values at the close of the next trading day. Dollar amounts displayed are estimates. Due to market fluctuations, the dollar amount at time of transfer may differ from the estimated dollar amounts.`
            this._emitEvent('SUCCESS')
          })
          .catch(error => {
            this.error = error && error.data && error.data.code || '99'
            this.$anchorScroll()
            this._emitEvent('FAILURE', this.error)
          })
          .then(() => {
            this.state = State.COMPLETE
            this.$anchorScroll()
          })
      // Hitting Transfer changes the data selection to read-only and the transfer button becomes “Confirm Transfer”
    }

  transfer () {
      this._emitEvent('transfer-submit')
      return this.overlayUtils.transition(this, this._transfer)
    }
}

FundsTransferController.$inject = ['$state', 'destinationFunds', 'currentFunds', 'fundTransferService', 'policyUtils', '$anchorScroll', 'overlayUtils', 'utils', 'authService']
