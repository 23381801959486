(function () {
  'use strict'

  WgPolicyRelativesCtrl.$inject = ['$scope', '$rootScope', '$window', '$state', '$stateParams', 'stateTransitions', 'CONSTANTS', 'clientUtils', 'configService', 'authService', 'PolicyRelativesViewManager', 'rolesPermissionService']

  function WgPolicyRelativesCtrl($scope, $rootScope, $window, $state, $stateParams, stateTransitions, CONSTANTS, clientUtils, configService, authService, PolicyRelativesViewManager, rolesPermissionService) {
    const self = this

    let innerWidth

    self.isClientPortal = configService.serverMode === 'client'
    self.isInsight = configService.serverMode === 'agent'
    self.beneficiariesAreEditable = configService.features.enableBeneficiaries || configService.features.enableSelfServeBeneficiaries
    self.canManageBeneficiaries = false
    self.isRpsPolicy = false


    // @TODO: check why this innerWidth code is required.
    innerWidth = $window.innerWidth

    angular.element($window).bind('resize', function () {
      $scope.$apply(function () {
        innerWidth = $window.innerWidth
      })
    })

    self.$onInit = function () {
      self.isRpsPolicy = self.summary.source === 'rps'
      self.currentSession = authService.getCurrentSession()

      window.onclick = function (event) {
        $scope.$applyAsync(() => self.viewStates.closeDropdowns())
      }

      if (configService.features.enableBeneficiaries) {
        const isDeathClaim = self.summary.policyStatus.toLowerCase().includes('death claim')

        self.showBeneficiariesButton = true
        self.canManageBeneficiaries = authService.isBeneficiaryManagmentAllowed(isDeathClaim)
        self.eligible = rolesPermissionService.clientCanManageBeneficiaries(self.summary, self.clients, self.beneficiaryMetadata)

      } else {
        self.eligible = rolesPermissionService.clientCanManageBeneficiaries(self.summary, self.clients, self.beneficiaryMetadata)

        self.showBeneficiariesButton = true
        self.canManageBeneficiaries = (configService.features.enableSelfServeBeneficiaries && self.eligible.eligible) || (!configService.features.enableSelfServeBeneficiaries && self.beneficiaryMetadata.featureFlagUserWhitelist.includes(self.currentSession.loggedInAs) && self.eligible.eligible)
      }

      // If the route names starts with `myPolicy` then we are using client view.
      self.inClientView = $state.current.name.indexOf('myPolicy') === 0

      self.viewStates = new PolicyRelativesViewManager(self.clients, self.isAnnuity, self.summary?.assignmentCode?.tc === '2')
    }

    self.getFontSize = function (label) {
      if (!self.areMultiplesOwners()) {
        if (innerWidth < 1250 && innerWidth >= 750) {
          if (label.length >= 26) {
            return '12px'
          } else if (label.length >= 23 && label.length < 26) {
            return '14px'
          } else if (label.length >= 20 && label.length < 23) {
            return '16px'
          }
        }
      }
      return '18px'
    }

    self.showClient = function (client, e) {
      stateTransitions.go('client.agent', {
        id: client.id,
      })

      e.stopPropagation()
    }

    self.isOwnerRelatable = function () {
      return self.clients.owners.length > 1 || (self.clients.owners.length === 1 && self.clients.owners[0].relatable)
    }

    self.isInsuredRelatable = function () {
      return self.clients.insureds.length > 1 || (self.clients.insureds.length === 1 && self.clients.insureds[0].relatable)
    }

    self.isPayorRelatable = function () {
      return self.clients.payors.length > 1 || (self.clients.payors.length === 1 && self.clients.payors[0].relatable)
    }

    self.clickRoleHandler = function (role, event) {
      // console.log('clickRoleHandler: role: %s, isAgentView: %s', role, $rootScope.auth.isAgentView())
      switch (role) {
        case 'Assignee':
          self.showAddresses(role)
          break

        case 'insureds':
        case 'owners':
          if ($rootScope.auth.isAgentView()) {
            self.showClientView(role, event)
          } else {
            self.showAddresses(role)
          }
          break

        case 'beneficiaries':
          if (self.isClientPortal) {
            if ((self.beneficiariesAreEditable && self.canManageBeneficiaries) || (!self.beneficiariesAreEditable && self.beneficiaryMetadata.featureFlagUserWhitelist.includes(self.currentSession.loggedInAs) && self.eligible.eligible)) {
              self.beneficiaryRoute(role)
            } else {
              self.showAddresses(role)
            }
          } else {
            if (self.viewStates.beneficiaries.disabled) return  // Might not be needed

            if (self.canManageBeneficiaries) {
              self.beneficiaryRoute(role)
            } else {
              self.showAddresses(role)
            }
          }

          break
      }
    }

    self.ownerClick = function (owner, event) {
      if (owner.relatable) { // TODO more precise check for Assignee
        self.showClient(owner, event)
      } else {
        self.clickRoleHandler('Assignee', event)
      }
    }

    // self.ownerLabel = function(hasMultiple) {
    //   const assigned = self.summary.assignmentCode ? self.summary.assignmentCode.tc === '2' : false
    //   const label = hasMultiple ? 'Multiple Owners' : 'Owner'
    //   const finalAnswer = label + (assigned ? ' (Assigned)' : '')

    //   return finalAnswer
    // }

    self.showAddresses = function (role) {
      if (role === 'insureds') {
        role = self.isAnnuity ? 'Annuitant' : 'Insured'
      } else if (role === 'owners') {
        role = 'Owner'
      } else if (role === 'beneficiaries') {
        role = 'Beneficiary'
      }

      if ($rootScope.auth.isAgentView() && role === 'Beneficiary' && self.summary.source !== 'rps' && self.canManageBeneficiaries) {
        self.beneficiaryRoute(role)
      }

      if (!self.canManageBeneficiaries || ($rootScope.auth.isAgentView() && self.clients.beneficiaries.length > 0 && (role !== 'Beneficiary' || (role === 'Beneficiary' && self.isRpsPolicy)))) {
        $state.go('policy.clientModal', {
          'id': self.summary.polNumber,
          'role': role,
          'eligibility': JSON.stringify(self.eligible),
        })
      } else if (!$rootScope.auth.isAgentView()) {
        $state.go('myPolicy.clientModal', {
          'id': self.summary.polNumber,
          'role': role,
          'eligibility': JSON.stringify(self.eligible),
        })
      }
    }

    self.beneficiaryRoute = function (role) {
      this.viewStates.closeDropdowns()

      if (self.showBeneficiariesButton && $rootScope.auth.isAgentView()) {
        $state.go('policy.changeBeneficiaries', { eligibility: self.eligible })
      } else {
        $state.go('myPolicy.clientBeneEdit.list', { id: $stateParams.id })
      }
    }

    self.showBeneficiaries = function (e) {
      self.viewStates.closeDropdowns()
      $state.go('beneficiaries', { pid: self.summary.polNumber })
      e.stopPropagation()
    }

    self.showClientView = function (role, event) {
      if ($rootScope.auth.isAgentView()) {
        const viewState = self.viewStates[role]

        if (!viewState.dropdownIsOpen && (viewState.hasMultiple || (!viewState.hasMultiple && viewState.isAssigned))) {
          self.viewStates.openDropdown(role)
        } else if (viewState.hasMultiple && viewState.dropdownIsOpen) {
          self.viewStates.closeDropdowns()
        } else if (!viewState.hasMultiple) {
          const client = viewState.list[0]

          self.viewStates.closeDropdowns()
          self.showClient(client, event)
        }
      }

      event.stopPropagation()
    }
  }

  angular.module('app.directives')
    .controller('WgPolicyRelativesCtrl', WgPolicyRelativesCtrl)
    .directive('wgPolicyRelatives', [function () {
      return {
        restrict: 'EA',
        scope: {
          clients: '=',
          isAnnuity: '=',
          summary: '=',
          showBeneficiariesTile: '=',
          beneficiaryMetadata: '=',
        },
        templateUrl: 'app/widgets/policy-relatives/wg-policy-relatives.html',
        controller: WgPolicyRelativesCtrl,
        controllerAs: 'ctrl',
        bindToController: true,
      }
    }])
})()
