// import { Beneficiary } from "../classes/beneficiary"

import * as angular from 'angular'
import { IDirective, IDirectiveFactory } from 'angular'
import { IAddressCountry } from '../../address/types'
import { Beneficiary } from '../classes/beneficiary'
import { TypeCodeValue } from '../classes/type-code-value'
import { ROLE_CONSTANTS } from '../relationships/role-relationships-service'
import { IEditableBeneficiary } from '../types'

export class EditBeneficiaryController {
  static $inject: any = ['$sce', 'deviceUtils', 'modalService', 'BENEFICIARIES_GENDERS', 'FINAL_BENEFICIARY_OPTIONS', 'BENEFICIARY_ROLES',
    'BENEFICIARY_PARTY_TYPES', 'BENEFICIARY_PREFIXES', 'BENEFICIARY_SUFFIXES', 'BENEFICIARY_PROF_SUFFIXES', '$scope', 'BENEFICIARY_DIST_OPTIONS', 'BENEFICIARIES_DIST_TYPES', 'BENEFICIARY_SETTLEMENT_OPTIONS', '$timeout', '$element', 'authService']

  FINAL_BENE_TYPE_KEYS = {
    ESTATE: '1',
    LAST_SURVIVING: '2',
    OTHER: '3',
  }

  relationships: any[]
  beneCtrl: any
  partyType: 'person' | 'business' | 'trust' | 'estate'
  bene: IEditableBeneficiary | undefined | null
  isEditMode: boolean = false
  // showBeneficiaryEditor: boolean
  currentBeneficiary: Beneficiary
  activeCategory: any
  policy: any
  finalBeneType: string
  finalBeneName: string
  formId: string
  beneForm: ng.IFormController
  genderList: TypeCodeValue[]
  acordCountries: IAddressCountry[]
  badText: any = {}
  onSave: Function
  onCancel: Function
  parentController: any
  focusElement: string
  currentDate: Date = new Date()
  canAccess: any

  constructor(private $sce, public deviceUtils, private modalService, BENEFICIARIES_GENDERS, public FINAL_BENEFICIARY_OPTIONS, public BENEFICIARY_ROLES, public BENEFICIARY_PARTY_TYPES, public BENEFICIARY_PREFIXES, public BENEFICIARY_SUFFIXES, public BENEFICIARY_PROF_SUFFIXES,
    private readonly $scope, public BENEFICIARY_DIST_OPTIONS, public BENEFICIARIES_DIST_TYPES, public BENEFICIARY_SETTLEMENT_OPTIONS, private $timeout, private $element, private authService) {
    this.genderList = BENEFICIARIES_GENDERS.map(gender => new TypeCodeValue(gender))
    this.canAccess = this.authService.getCanAccessResponse()
  }

  get disableSaveButton(): boolean {
    if (!this.$scope.beneEditorForm) {
      return true
    }

    return this.$scope.beneEditorForm.$pristine || (this.$scope.beneEditorForm.$dirty && this.$scope.beneEditorForm.$invalid)
  }

  // If don't have canmanageClientInfoTaxId, can't edit SSN
  get disableTaxID(): boolean {
    return !this.canAccess.canManageClientInfoTaxId
  }

  // If dont have canmanageClientInfo, can't edit Name and DOB
  get disableClientInfo(): boolean {
    return !(this.canAccess.canManageClientInfoTaxId || this.canAccess.canManageClientInfo)
  }

  openTextTemplates() {
    this.modalService.open('app/client/beneficiaries/editors/templates/text-templates.html', {
      textTemplates: this.FINAL_BENEFICIARY_OPTIONS,
      selectTemplate: (template) => {
        if (this.bene) {
          this.bene.name.fullName = template.value
          this.beneForm.$setDirty()
        }
        this.modalService.modalInstance.close()
      },
    }, { size: 'lg' })

  }

  $onInit() {
    this.isEditMode = Boolean(this.bene)

    if (this.bene && this.isEditMode) {
      // this.formId = 'beneEditor' + this.bene?.roleId + this.bene?.id.split('-').join('')

      if (this.bene?.name?.fullName) {
        this.finalBeneName = this.bene.name.fullName
        this.finalBeneType = '3'
      }
      this.bene.isEditMode = true

      if (this.bene.partyType === 'estate') {
        this.FINAL_BENEFICIARY_OPTIONS[0].value = this.FINAL_BENEFICIARY_OPTIONS[0].value.replace('{estateType}', this.policy.isAnnuity ? 'Annuitant' : 'Insured')
      }

      // When adding a new trust default relationship to 'trust' when there is no relationship defined.
      if (this.bene.partyType === 'trust' && !this.bene.shareDistribution.relationDescription) {
        this.bene.shareDistribution.relationDescription = ROLE_CONSTANTS.RELATION_TYPE_CODES[ROLE_CONSTANTS.RELATION_NAMES.TRUST]
      }
    }
    this.$timeout(() => {
      const inputField = this.$element[0].querySelector(this.focusElement)
      if (inputField) {
        inputField.focus()
      }
    }, 0)
  }

  handleBadCharacters(invalidTextKey: string, invalidText: string): void {
    this.badText[invalidTextKey] = this.$sce.trustAsHtml(invalidText)
  }

  save(): void {
    this.onSave({ beneEditorForm: this.beneForm })
  }

  $doCheck(): void {
    if (!this.beneForm) {
      this.beneForm = this.$scope.beneEditorForm
    }
  }
}

/**
 * Renders the <training-list /> directive as a list for buttons.
 */
export class EditBeneficiaryDirective implements IDirective {
  restrict = 'EA'
  replace = true
  bindToController = true
  scope = {
    relationships: '=',
    policy: '=',
    bene: '=',
    partyType: '=',
    acordCountries: '=',
    onSave: '&',
    onCancel: '&',
    parentController: '=',
    focusElement: '<',
    metaData: '<',
  }

  controller = 'editBeneficiaryController'
  controllerAs = 'ctrl'
  templateUrl = (_el, attrs): string => {
    const url: string = (attrs.userType === 'client' ? 'app/client/client-bene-edit/tmpl/' : 'app/client/beneficiaries/editors/templates/') + `${attrs.editBeneficiary}.html`

    return url
  }

  /**
   * Creates an instance of TrainingsDirective, with dependencies injected.
   */
  static factory(): IDirectiveFactory {

    const directive = () => new EditBeneficiaryDirective()

    directive.$inject = []

    return directive
  }
}
