export {appRun}
appRun.$inject = ['routerHelper']

  /* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function setClientStatusTitle($rootScope) {
  $rootScope.state = 'client'
  $rootScope.pageTitle = 'Client Page'
  $rootScope.mobileHeaderTitle = 'Client Page'
  $rootScope.isPopupVisible = false
}

function getStates() {
  return [{
    state: 'client.agent',
    config: {
      url: '/myclients/client/:id',
      templateUrl: 'app/client/advisor/client-view.html',
      controller: 'ClientController',
      controllerAs: 'client',
      data: {
        secure: true,
      },
      resolve: {
        messages: function() { return null },
        payablePolicies: [function() {
          return {
            data: {
              actions: [],
            },
          }
        }],
      },
      onEnter: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.agent.correspondence',
    config: {
      url: '/correspondence',
      templateUrl: 'app/correspondence/correspondence-view.html',
      controller: 'CorrespondenceController',
      controllerAs: 'correspondenceCtrl',
      data: {
        secure: true,
      },
      resolve: {
        pids: ['$stateParams', 'clientAdvisorService', function($stateParams, clientAdvisorService) {
          return clientAdvisorService
              .getClientInfo($stateParams.id)
              .then(clientAdvisorService.getClientInfoPolicyIds)
        }],
        correspondenceResult: ['pids', 'correspondenceService',
          function(pids, correspondenceService) {
            return correspondenceService.getCorrespondences(pids)
          },
        ],
        taxDocuments: [() => []], // CP does not need this injected, but CorrespondenceController shared with insight.
      },
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.state = 'client-correspondence'
        $rootScope.pageTitle = 'Client Correspondence'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.agent.viewAddresses',
    config: {
        // url: '/addresses',
      params: {addresses: []},
      templateUrl: 'app/client/address-view.html',
      controller: 'clientAddressesController',
      controllerAs: 'ctrl',
      resolve: {},
      onEnter: ['$rootScope', function($rootScope) {
        const pageTitle = 'View Client Addresses'

        $rootScope.pageTitle = pageTitle
            // $rootScope.isPopupVisible = true
            // $rootScope.state = 'policy-roles-details'
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.agent.manageAddresses',
    config: {
      url: '/manage-addresses',
      templateUrl: 'app/client/address/manage-addresses.html',
      controller: 'ManageAddressesController',
      controllerAs: 'manageAddressesCtrl',
      data: {
        secure: true,
      },
      resolve: {
        addressesResult: ['clientAddressService', '$stateParams', (clientAddressService, $stateParams) => clientAddressService.getAddresses($stateParams.id)],
      },
        // clientAppOnly: true,
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.pageTitle = 'Manage Addresses'
        $rootScope.state = 'manage-addresses'
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  ]
}
