  // @TODO: add socket dependency back in for Notification support
MainController.$inject = ['$scope', '$rootScope', 'authService', 'deviceUtils', 'stateTransitions', '$state',
  'userAccountService', 'popupManager', 'CONSTANTS', 'notificationCenterService', '$location', 'clientMeService', 'clientService', 'configService', 'Idle']

  // @TODO: add socket dependency back in for Notification support
  /* @ngInject */
function MainController($scope, $rootScope, authService, deviceUtils, stateTransitions, $state, userAccountService, popupManager, CONSTANTS, notificationCenterService, $location, clientMeService, clientService, configService, Idle) {
  const self = this
  const MAIN_MENU_ID = CONSTANTS.popups.MAIN_MENU_ID

  $rootScope.featureFlags = configService.features
  $rootScope.darkBackground = false
  $rootScope.scrolling = false
  $rootScope.isPopupVisible = false
  $rootScope.asOfDate = ''
  $rootScope.showAsOfDate = false
  $rootScope.showPaymentMethodsMenuItem = false

    // these are updated and used in init()
  self.timeoutValue = Idle.getTimeout()
  self.idleValue = Idle.getIdle()
  self.idle = Idle

  $rootScope.environment = configService.environment
  $rootScope.serverMode = configService.serverMode
  self.sessionDebugOn = Boolean(configService.features?.enabledIdleSessionDebug)

  self.features = $scope.$root.featureFlags

  self.goBack = function(notAnimation) {
    if ($state.current.name === 'myPolicy.values') {
      $rootScope.$broadcast('CLOSE_POLICY_VALUE_POPUP')
    }
    stateTransitions.goBack(notAnimation)
  }

  self.isAuthenticated = function() {
    return authService.isAuthenticated()
  }

  self.isMainMenuOpen = function() {
    return popupManager.isOpen(CONSTANTS.popups.MAIN_MENU_ID)
  }

  self.openMainMenu = function(e) {
    let state

    if (deviceUtils.isMobilePlatform()) {
      state = $state.current.name

      if (!popupManager.isOpen(MAIN_MENU_ID) && !$rootScope.isPopupVisible && state !== 'login' && state !== '403') {
        popupManager.open(MAIN_MENU_ID, e)
      }
    }
  }

  self.closeMainMenu = function(e) {
    popupManager.close(MAIN_MENU_ID, e)
  }

  self.openPML = function() {
    const url = 'http://www.pennmutual.com'

    window.open(encodeURI(url), '_system')
  }

  self.gotToClientPortalHome = function() {
    return $state.go('client.me')
  }

    // $rootScope.logout = function() {
    //   authService.logout('main controller, user invoked')
    // }

  self.showTermsAndConditions = showTermsAndConditions

  self._refreshPaymentMethods = function() {
    clientService.getPaymentAccounts(self.features.enableCreditCards).then(
      function(payload) {
        $rootScope.showPaymentMethodsMenuItem = payload.status !== 400 // 400 means they can't update payment accounts yet
      }
    )
  }

  function showTermsAndConditions() {
    $rootScope.$broadcast(CONSTANTS.events.CLOSE_POPUPS_AND_MENUS)

    if ($rootScope.isAgentView) {
      $state.go('terms-and-conditions')
    } else {
      $state.go('client.me.terms-and-conditions')
    }
  }

  self._closePopupsAndMenus = function(event) {
    let refresh = false

    if (isNoClickable(event)) {
      if (event && event.stopPropagation) {
        event.stopPropagation()
      }
      return
    }

    if ($rootScope.isInsuredsDropdownVisible) {
      $rootScope.isInsuredsDropdownVisible = false
      refresh = true
    } else if ($rootScope.isOwnersDropdownVisible) {
      $rootScope.isOwnersDropdownVisible = false
      refresh = true
    } else if ($rootScope.isPayorsDropdownVisible) {
      $rootScope.isPayorsDropdownVisible = false
      refresh = true
    } else if (popupManager.closeAll(event)) {
      refresh = true
    }

    if (!$rootScope.$$phase && refresh) {
      $rootScope.$apply()
    }
  }

  function isNoClickable(event) {
    return event && event.target.className.indexOf && event.target.className.indexOf('noclickable') !== -1
  }

    // function checkAndCloseMenu(menuVar, exclude, event) {
    //   if ($rootScope[menuVar]) {
    //     if (event && event.target.className.indexOf(exclude) !== -1) {
    //       return false
    //     }

    //     $rootScope[menuVar] = false
    //     return true
    //   }
    //   return false
    // }

  self.resetEvents = function() {
    Idle.watch()
    self.timeoutValue = Idle.getTimeout()
    self.idleValue = Idle.getIdle()
    $rootScope.idleEvents = []
  }

  $scope.$on(CONSTANTS.events.CLOSE_POPUPS_AND_MENUS, function(event) {
    self._closePopupsAndMenus()
  })

  $rootScope.$on(CONSTANTS.events.REFRESH_PAYMENT_METHODS, function() {
    self._refreshPaymentMethods()
  })

  window.onclick = function(event) {
    self._closePopupsAndMenus(event)
  }

  function touchEndEventHandler(event) {
    if ($rootScope.scrolling) return

    if (deviceUtils.isMobilePlatform()) {
      if (menuIsScrollable(event)) {
          // menu is scrollable.  something could be called here or do away with function
      }

        // self._closePopupsAndMenus(event);
    }
  }

  function touchMoveEventHandler(event) {
    $rootScope.scrolling = true

    window.ontouchend = function(touchendEvent) {
      window.ontouchend = touchEndEventHandler
      $rootScope.scrolling = false
    }
  }

  window.ontouchend = touchEndEventHandler
  window.ontouchmove = touchMoveEventHandler

  function menuIsScrollable(e) {
    if (e && e.target && e.target.offsetParent && e.target.offsetParent.className && e.target.offsetParent.className.indexOf('alert-menu') >= 0) {
      return true
    }
    return false
  }

  init()

  function init() {
    let userId

    window.environment = {
      environment: $rootScope.environment,
      serverMode: $rootScope.serverMode,
    }

    if (self.isAuthenticated() && authService.isARealClient()) {
      self._refreshPaymentMethods()
    }

    if (authService.isAuthenticated()) {
      userId = authService.getLogin()

      if (userId && window.dataLayer && !$rootScope.isAgentView) {
        clientMeService.getAceInfo()
            .then(function(aceInfo) {
              dataLayer.push({
                'event': 'login',
                'aceClient': aceInfo.isAceClient,
              })
            })
      }

      userAccountService.getUserAccount(true)

        /* @TODO: Uncomment for Notification support
                        socket.on(CONSTANTS.SOCKET_MESSAGE.CONNECT, function(){
                            console.log(CONSTANTS.SOCKET_MESSAGE.CONNECT + "!");
                        });

                        socket.on(CONSTANTS.SOCKET_MESSAGE.NOTIFY_CLIENT, function(data){
                            console.log(CONSTANTS.SOCKET_MESSAGE.NOTIFY_CLIENT + ": " + data.message);

                            notificationCenterService.removeById(CONSTANTS.eventId.NOTIFY_CLIENT);

                            if(data.message && data.message !== "" && currentSession.isClient){
                                notificationCenterService.add(CONSTANTS.eventType.info, data.message, CONSTANTS.notificationType.systemUnavailability, CONSTANTS.eventId.NOTIFY_CLIENT, {"status": "shown", "top": true});
                            }
                        });

                        socket.on(CONSTANTS.SOCKET_MESSAGE.NOTIFY_AGENT, function(data){
                            console.log(CONSTANTS.SOCKET_MESSAGE.NOTIFY_AGENT + ": " + data.message);

                            notificationCenterService.removeById(CONSTANTS.eventId.NOTIFY_AGENT);

                            if(data.message && data.message !== "" && !currentSession.isClient){
                                notificationCenterService.add(CONSTANTS.eventType.info, data.message, CONSTANTS.notificationType.systemUnavailability, CONSTANTS.eventId.NOTIFY_AGENT, {"status": "shown", "top": true});
                            }
                        });

                        socket.on(CONSTANTS.SOCKET_MESSAGE.DISCONNECT, function(){
                            console.log(CONSTANTS.SOCKET_MESSAGE.DISCONNECT + "!");
                        });
        */
    }
  }
}

export {MainController}
