class WgClientInfoHeaderCtrl {

  oldestPolicyDate: Date
  public clientHasTaxForms: boolean = false
  public isAnOwner: boolean = false
  public client: any
  public canChangeAddress: boolean
  private addresses: any[]
  public hasMultipleAddresses: boolean
  public addressCount: number
  public emailAddress: string

  constructor (private authService, private policyService, private $q, private configService, private clientAddressService, private $state) {

    // if (!this.client) {
    //   this.client = $scope.client // For testing purposes because of our limitation in how we create the controller.
    // }

    // this.isAnOwner = this.client.clientData?.isOwner

  }

  $onInit() {
    this.isAnOwner = this.client.clientData?.isOwner
    this.getAddressInfo(this.client.clientData.id)
    this.emailAddress = this?.client?.clientData?.emailAddress?.[0]?.addrLine || ''

    if (this.authService.isAClient()) {
      return this.policyService.taxDocumentSearch().then(result => {
        this.clientHasTaxForms = Boolean(result?.data?.documents)
        return Boolean(result?.data?.documents)
      })
    }

    this.canChangeAddress = this._canChangeAddress() && this.client?.clientData?.isOwner
  }

  public openAddressView() {
    this.$state.go('client.agent.viewAddresses', {
      addresses: this.addresses,
    })
  }

  showAsOwner(): boolean {
    if (!this.client.clientData.policies.length) {
      return false
    }

    return this.isAnOwner && this.client.clientData.policiesInfo.annuityPolicies && !this.client.clientData.policiesInfo.lifePolicies
  }

  getOldestPolicyDate() {
    if (this.oldestPolicyDate) {
      return this.oldestPolicyDate
    }

    if (this.client.clientData?.policiesInfo?.oldestEffectveDate) {
      this.oldestPolicyDate = new Date(Date.parse(this.client.clientData.policiesInfo.oldestEffectveDate))
      return this.oldestPolicyDate
    }

    return null
  }

  hasTaxForms() {
    return this.clientHasTaxForms
  }

  private getAddressInfo(clientId: string) {
    this.clientAddressService.getAddressesByClientId(clientId).then((addressArray) => {
      this.addresses = addressArray
      this.addressCount = this.addresses.length
      this.hasMultipleAddresses = this.addressCount > 1
    })
  }

  // MP - Should this be moved to clientAddressService ???
  private _canChangeAddress() {

    if (this.configService.features.homeOfficeChangeAddress) {
      return this.authService.isHomeOfficeAllowed('canChangeAddress') && this.client?.clientData?.isOwner
    }

    return this.$q.resolve(false)
  }

}

WgClientInfoHeaderCtrl.$inject = ['authService', 'policyService', '$q', 'configService', 'clientAddressService', '$state']

angular.module('app.directives')
    .controller('WgClientInfoHeaderCtrl', WgClientInfoHeaderCtrl)
    .component('wgClientInfoHeader', {
      bindings: {
        client: '<',
      },
      templateUrl: 'app/widgets/templates/client-info-header.html',
      controller: WgClientInfoHeaderCtrl,
      controllerAs: 'wgClientInfoHeaderCtrl',
    })
